import React, { Dispatch, SetStateAction, useEffect } from "react";
import Modal from "react-modal"
import styles from "./information.module.scss";
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import OutlineTextInput from "../../../components/input/OutlineTextInput";
import ImagePlaceholder from "../../../assets/images/Icons/image-placeholder.png";
import { ReactComponent as SaveIcon } from "../../../assets/images/Icons/save.svg";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  openLoader,
  closeLoader
} from "../../../redux/actions/loader/loaderActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../../constants/toastStyles";
import { useDispatch, useSelector } from "react-redux";
import client, { formClient } from "../../../api/client";
import { customStyles } from "../../../constants/modalStyles";

type SelectionProp = {
  selection?: string;
  setSelection: Dispatch<SetStateAction<string>>;
};
const Information = ({ selection, setSelection }: SelectionProp) => {
  interface initTypes {
    directorname: string;
    idnumber: string;
    id: string;
    bvn: string;
  }

  const dispatch = useDispatch();
  const initProps: initTypes = {
    directorname: "",
    idnumber: "",
    id: "",
    bvn: "",
  };
  const [inputs, setInputs] = React.useState(initProps);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [directorDetails, setDirectorDetails] = React.useState<any>([])

  function closeModal() {
    setIsOpen(false);
  }


  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };
  const updateImageProps = (event: any) => {
    const newValue = event?.target?.files[0];
    const inputName = event?.target?.name;
    setInputs((prevState: any) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const fetchDirectorDetails = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(
        `/subsidiary/dashboard/kyc/director/view`
      );
      const directorDetails = data?.data;
      setDirectorDetails(directorDetails)
      setInputs({
        ...inputs,
        directorname: directorDetails?.name,
        idnumber: directorDetails?.identification
      });
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;

      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  let formData = new FormData();

  formData.append("directorname", inputs.directorname); //append the values with key, value pair
  formData.append("idnumber", inputs.idnumber);
  formData.append("id", inputs.id);
  formData.append("bvn", inputs.bvn);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    formClient
      .post("/subsidiary/dashboard/kyc/director/submit", formData)
      .then((data: any) => {
        dispatch(closeLoader());
        setIsOpen(true)
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles
          })
        );
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles
          })
        );
      });
  };

  useEffect(() => {
    fetchDirectorDetails();
  }, []);

  return (
    <div className={styles.business}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.successModal}>
          <CheckCircleOutlineIcon color="success" fontSize="large" />
          <span>You have successfully created/updated this directors information</span>
          <p className={styles.infoText}>Would you like to add another director or proceed to the next step?</p>
          <div className={styles.actionButton}>
            <div className={styles.add}
              onClick={
                () => {
                  closeModal()
                  fetchDirectorDetails()
                  setInputs({
                    ...inputs,
                    directorname: "",
                    idnumber: "",
                    id: "",
                    bvn: "",
                  })
                }
              }
            >Add</div>
            <div className={styles.uploadActionButton}
              onClick={
                () => {
                  closeModal()
                  setSelection("Kyc");
                }
              }
            >Proceed</div>
          </div>
        </div>
      </Modal>
      {
        directorDetails?.length > 0 && directorDetails?.map((detail: any) => {
          return (
            <div className={styles.details}>
              {detail?.valid_identification && <img src={detail?.valid_identification} height={20} width={20} alt={detail.name} />}
              <div className={styles.info}>
                <span className={styles.name}>{detail.name}</span>
                <span className={styles.identification}>ID: {detail.identification}</span>
              </div>
            </div>
          )
        })
      }
      <div className={styles.businessCard} style={{ marginTop: "30px" }}>

        <div className={styles.logoText}>{directorDetails?.length > 0 ? "Add another director" : "Add Director"}</div>
        <div className={styles.businessForm}>
          <form className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="directorname"
              inputLabel="Director’s full name"
              inputValue={inputs?.directorname}
            />
            <OutlineTextInput
              inputName="id"
              inputLabel="Director's ID"
              handleChange={updateImageProps}
              inputType="file"
              inputShrink
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="idnumber"
              inputLabel="ID number"
              inputValue={inputs?.idnumber}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="bvn"
              inputLabel="BVN"
              inputValue={inputs?.bvn}
            />
          </form>
          <div
            className={styles.uploadButton}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            <SaveIcon /> <span>Save and Continue</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
