import React from "react";
import styles from "./Forgotpassword.module.scss";
import Logo from "../../assets/images/Logos/Pay-Invert-Logo.svg";
import { ReactComponent as SignInIcon } from "../../assets/images/Icons/signin.svg";
import { ReactComponent as AccountIcon } from "../../assets/images/Icons/account.svg";
import OutlineTextInput from "../../components/input/OutlineTextInput";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { saveSubsidiaryInfo } from "../../redux/actions/subsidiaryInfo/subsidiaryInfoActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import ForgotPasswordModal from "../../components/ModalContent/ForgotPasswordModal/ForgotPasswordModal";

const ForgotPassword = () => {
  const todaysDate = new Date();
  const dispatch = useDispatch();
  const history = useHistory();
  const { email } = useParams<{ email: string }>();

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  interface initTypes {
    otp?: string;
    new_password?: string;
  }

  const initProps: initTypes = {
    otp: "",
    new_password: ""
  };
  const [inputs, setInputs] = React.useState(initProps);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const handleSubmit = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post(
        "/subsidiary/dashboard/complete/forgot-password",
        {
          ...inputs,
          identifier: email
        }
      );

      const message = data?.data?.message;
      dispatch(closeLoader());
      history.push(`/`);
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  return (
    <div className={styles.signInBody}>
      <div className={styles.logo}>
        <img src={Logo} width={207} height={56} alt="pay-invert" />
      </div>
      <div className={styles.signinForm}>
        <div className={styles.signIn}>Password</div>
        <div className={styles.businessForm}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="otp"
              inputLabel="OTP"
              inputValue={inputs.otp}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="new_password"
              inputLabel="New Password"
              inputType="password"
              inputValue={inputs.new_password}
            />

            <div onClick={handleSubmit} className={styles.signInButton}>
              <SignInIcon />
              <span>Reset Password</span>
            </div>
          </form>
        </div>
      </div>

      <div className={styles.sidebarFooter}>
        <div>Copyright &#169; Payinvert {todaysDate.getFullYear()}.</div>
        <div>All rights reserved</div>
      </div>
    </div>
  );
};

export default ForgotPassword;
