import React, { useState, ReactNode, Fragment, useEffect } from "react";
import styles from "./Invoicemenu.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import { ReactComponent as LinkIcon } from "../../../assets/images/Icons/link-icon.svg";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import CopyIcon from "../../../assets/images/Icons/copy.svg";
import LinkOffIcon from "../../../assets/images/Icons/link-off.svg";
import Menus from "../Menus/Menus";

type MenuProps = {
  menuContent: string;
};
const InvoiceMenu = ({ invoiceData }: any) => {
  const [menuOption, setMenuOption] = useState(false);
  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <Menus
                icon={LinkOffIcon}
                optionName="Initiate Payment"
                onclick={() =>
                  window
                    .open(
                      `${process.env.REACT_APP_MERCHANT_PAGE}/invoice/${invoiceData?.payment_link}/${invoiceData?.reference}`,
                      "_blank"
                    )
                    ?.focus()
                }
              />
            </div>
          )}
        </div>
      </ClickAwayListener>
    </Fragment>
  );
};

export default InvoiceMenu;
