import React, { useEffect } from "react";
import styles from "./Addnewsub.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client, { formClient } from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { ReactComponent as UploadIcon } from "../../../assets/images/Icons/upload-image.svg";
import ImagePlaceholder from "../../../assets/images/Icons/image-placeholder.png";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../../constants/toastStyles";
import useApi from "../../../hooks/useApi";
import countriesApi from "../../../api/countries";
import Select from "react-select";
import Radio from "@mui/material/Radio";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ReactComponent as InfoIcon } from "../../../assets/images/Icons/info.svg";
import { ReactComponent as SaveIcon } from "../../../assets/images/Icons/save.svg";

import {
  closeLoader,
  openLoader
} from "../../../redux/actions/loader/loaderActions";

const AddNewSub = ({ closeModal }: any) => {
  const getCountriesApi = useApi(countriesApi.getCountries);

  const fetchCountries = async () => {
    getCountriesApi.request();
  };

  const [selectedValue, setSelectedValue] = React.useState("");

  const [selectedCountry, setSelectedCountry] = React.useState<any>();

  let selectedCountryOptions = getCountriesApi?.data?.data?.map(function (
    selectedCountry: any
  ) {
    return {
      value: selectedCountry.short_name,
      label: selectedCountry.name,
      currency: selectedCountry.country_code
    };
  });
  const dispatch = useDispatch();
  interface initTypes {
    name_of_subsidiary: string;
    description: string;
    country: string;
    email?: string;
    avatar?: any;
    icon?: any;
    Individual: string;
    Company: string;
    subsidiary_support_email: string;
    mobile_number: string;
  }

  const initProps: initTypes = {
    name_of_subsidiary: "",
    subsidiary_support_email: "",
    description: "",
    country: "",
    email: "",
    avatar: "",
    icon: "",
    Individual: "Individual",
    Company: "Company",
    mobile_number: ""
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (
      !!inputs.name_of_subsidiary ||
      !!inputs.country ||
      !!selectedValue ||
      !!selectedCountry ||
      !!inputs.description ||
      !!inputs.email ||
      !!inputs.avatar ||
      !!inputs.icon
    ) {
      return true;
    }
  };


  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const createPaymentLink = async () => {
    let formData: any = new FormData();

    formData.append("email", inputs.email); //append the values with key, value pair
    formData.append("country", selectedCountry?.value);
    formData.append("mobile_number", inputs.mobile_number);
    formData.append("name_of_subsidiary", inputs.name_of_subsidiary);
    formData.append("business_type", selectedValue);
    formData.append("description", inputs.description);
    // formData.append("description", inputs.description);
    formData.append("image", inputs?.avatar);
    formData.append("support_email", inputs?.subsidiary_support_email);

    dispatch(openLoader());
    try {
      const data: any = await formClient.post(
        "/subsidiary/dashboard/create-subsidiary",
        formData
      );

      const message = data?.data?.message;
      dispatch(closeLoader());
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      dispatch(closeLoader());
      closeModal();

      if (error?.response?.data?.status_code === 401) {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createPaymentLink();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };
  const updateImageProps = (event: any) => {
    const newValue = event?.target?.files[0];
    const inputName = event?.target?.name;
    setInputs((prevState: any) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#016E20"
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#016E20",
      fontFamily: "'Kumbh Sans'",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      maxWidth: 260,
      padding: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      letterSpacing: "-0.006em",
      color: "#FFFFFF",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.12)",
      borderRadius: "4px"
    }
  }));

  useEffect(() => {
    fetchCountries();
  }, []);
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Add new subsidiary</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="name_of_subsidiary"
            inputLabel="Business Name"
            inputValue={inputs.name_of_subsidiary}
          />
          <div>
            <label className={styles.inputLabel}>Country</label>
            <Select
              defaultValue={selectedCountry}
              onChange={setSelectedCountry}
              options={selectedCountryOptions}
              // styles={customStyles}
              className={styles.select}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 })
              }}
            />
          </div>
          {/* <div className={styles.profileCard}>
            <div className={styles.logoText}>Business Logo</div>

            <div className={styles.imagePlaceholder}>
              <img
                src={inputs?.avatar ? inputs?.avatar : ImagePlaceholder}
                alt="logo-placeholder"
                width={100}
                height={100}
                style={{ borderRadius: "8px" }}
              />
            </div>
            <div className={styles.uploadButton}>
              <UploadIcon />

              <input type="file" name="avatar" onChange={updateImageProps} />
            </div>
          </div> */}
          <div className={styles.profileCard}>
            <div className={styles.logoText}>Business Logo</div>

            <div className={styles.imagePlaceholder}>
              <img
                src={
                  inputs?.avatar
                    ? URL.createObjectURL(inputs?.avatar)
                    : ImagePlaceholder
                }
                alt="logo-placeholder"
                width={100}
                height={100}
                style={{ borderRadius: "8px" }}
              />
            </div>
            {inputs?.avatar ? (
              <div className={styles.uploadButton}>
                <span> Logo</span>
              </div>
            ) : (
              <div className={styles.uploadButton}>
                <UploadIcon />

                <input
                  type="file"
                  name="avatar"
                  // value={inputs?.avatar}
                  onChange={updateImageProps}
                />
              </div>
            )}
          </div>
          {/* <div className={styles.profileCard}>
            <div className={styles.logoText}>Business icon</div>
            <div className={styles.recommend}>
              We recommend an image of at least 400x400
            </div>
            <div className={styles.imagePlaceholder}>
              <img
                src={
                  inputs?.icon
                    ? URL.createObjectURL(inputs?.icon)
                    : ImagePlaceholder
                }
                alt="logo-placeholder"
                width={100}
                height={100}
                style={{ borderRadius: "8px" }}
              />
            </div>
            {inputs?.icon ? (
              <div className={styles.uploadButton}>
                <span> Logo</span>
              </div>
            ) : (
              <div className={styles.uploadButton}>
                <UploadIcon />

                <input
                  type="file"
                  name="avatar"
                  // value={inputs?.avatar}
                  onChange={updateImageProps}
                />
              </div>
            )}
          </div> */}
          <OutlineTextInput
            handleChange={updateProps}
            inputName="description"
            inputLabel="Description"
            inputValue={inputs.description}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="email"
            inputLabel="Business Email"
            inputValue={inputs.email}
          />{" "}
          <OutlineTextInput
            handleChange={updateProps}
            inputName="subsidiary_support_email"
            inputLabel="Support Email"
            inputValue={inputs.subsidiary_support_email}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="mobile_number"
            inputLabel="Mobile Number"
            inputValue={inputs.mobile_number}
          />
          <div className={styles.businessType}>Business type</div>
          <div className={styles.formSelection}>
            <div className={styles.radioSelection}>
              <Radio
                checked={selectedValue === inputs.Individual}
                onChange={handleChange}
                value={inputs.Individual}
                name="Individual"
                inputProps={{ "aria-label": inputs.Individual }}
                size="small"
              />
              <div className={styles.radioLabel}>
                Individual{" "}
                <LightTooltip
                  title="Description of what business type 'individual' is"
                  placement="top"
                  arrow
                >
                  <IconButton>
                    <InfoIcon className={styles.infoIcon} />
                  </IconButton>
                </LightTooltip>
              </div>
            </div>
            <div className={styles.radioSelection}>
              <Radio
                checked={selectedValue === inputs.Company}
                onChange={handleChange}
                value={inputs.Company}
                name="Company"
                inputProps={{ "aria-label": inputs.Company }}
                size="small"
              />
              <div className={styles.radioLabel}>
                Company{" "}
                <LightTooltip
                  title="Description of what business type 'company' is"
                  placement="top"
                  arrow
                >
                  <IconButton>
                    <InfoIcon className={styles.infoIcon} />
                  </IconButton>
                </LightTooltip>
              </div>
            </div>
          </div>
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Create new subsidiary
          </div>
        ) : (
          <div className={styles.notClickable}>Create new subsidiary</div>
        )}
      </div>
    </div>
  );
};

export default AddNewSub;
