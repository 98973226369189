import React, {
  Dispatch,
  SetStateAction,
  useState,
  ReactNode,
  Fragment,
  useEffect
} from "react";
import styles from "./Transactionsmenu.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import RefundIcon from "../../../assets/images/Icons/refund-icon.svg";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import OutlineTextInput from "../../input/OutlineTextInput";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../../constants/toastStyles";
import { useHistory } from "react-router-dom";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";

type MenuProps = {
  transactionId: number;
  fetchTransactions: () => void;
  amount: number;
  customerId?: number;
  status?: string;
};
const TransactionsMenu = ({
  transactionId,
  fetchTransactions,
  amount,
  customerId,
  status
}: MenuProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [menuOption, setMenuOption] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  //   const viewTransactionDetails = async (e: any) => {
  //     e.preventDefault();
  //     try {
  //       const data: any = await client.post(
  //         `/subsidiary/dashboard/fetch/customers/orders?customerid=${customerId}`
  //       );
  //       const message = data?.data?.message;
  //       dispatch(
  //         openToastAndSetContent({
  //           toastStyles: ToastSuccessStyles,
  //           toastContent: message,
  //         })
  //       );
  //     } catch (error: any) {
  //       const { message } = error?.response?.data;
  //       dispatch(
  //         openToastAndSetContent({
  //           toastStyles: ToastErrorStyles,
  //           toastContent: message,
  //         })
  //       );
  //     }
  //   };

  interface initTypes {
    amount: number;
  }

  const initProps: initTypes = {
    amount: amount
  };
  const [inputs, setInputs] = React.useState(initProps);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const refundCustomer = async () => {
    try {
      const data: any = await client.post("/subsidiary/dashboard/refund", {
        ...inputs,
        order_id: transactionId
      });
      const message = data?.data?.message;
      closeModal();
      fetchTransactions();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    refundCustomer();
  };
  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <Menus
                icon={ViewIcon}
                optionName={"View details"}
                onclick={(e: any) => {
                  history.push(
                    `/dashboard/business/transactions/transaction-details/${transactionId}`
                  );
                }}
              />
              {status === "Successful" && (
                <Menus
                  icon={RefundIcon}
                  optionName="Refund"
                  onclick={() => setIsOpen(true)}
                />
              )}
            </div>
          )}
        </div>
      </ClickAwayListener>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <div className={styles.background}>
              <div className={styles.linkDetails}>Refund customer</div>
              <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
            </div>
          </div>
          <div className={styles.businessForm}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <div className={styles.transactionsAmount}>
                Transaction Amount <span>NGN{amount}</span>
              </div>
              <OutlineTextInput
                handleChange={updateProps}
                inputName="amount"
                inputLabel="Amount"
                inputValue={inputs.amount}
              />
            </form>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.cancel} onClick={closeModal}>
              Cancel
            </div>
            {!!amount ? (
              <div className={styles.deactivate} onClick={handleSubmit}>
                Refund Customer
              </div>
            ) : (
              <div className={styles.notClickable}>Refund Customer</div>
            )}
          </div>
        </div>
      </Modal>
    </Fragment>
  );
};

export default TransactionsMenu;
