import React from "react";
import styles from "./Signin.module.scss";
import Logo from "../../assets/images/Logos/Pay-Invert-Logo.svg";
import { ReactComponent as SignInIcon } from "../../assets/images/Icons/signin.svg";
import { ReactComponent as AccountIcon } from "../../assets/images/Icons/account.svg";
import OutlineTextInput from "../../components/input/OutlineTextInput";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { saveSubsidiaryInfo } from "../../redux/actions/subsidiaryInfo/subsidiaryInfoActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../constants/toastStyles";

const TwoFaSignin = () => {
  const todaysDate = new Date();
  const dispatch = useDispatch();
  const history = useHistory();

  const { subsidiaryInfo } = useSelector(
    (state: any) => state.subsidiaryInfoReducer
  );

  const loginHash = subsidiaryInfo?.data?.login_hash?.login_hash;

  interface initTypes {
    otp: number | undefined;
  }

  const initProps: initTypes = {
    otp: undefined,
  };
  const [inputs, setInputs] = React.useState(initProps);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post("/subsidiary/dashboard/complete/login", {
        ...inputs,
        email: subsidiaryInfo.email,
      })
      .then((data: any) => {
        dispatch(closeLoader());
        dispatch(saveSubsidiaryInfo(data));
        const { status, message, token } = data.data;
        const { access_token } = token;
        sessionStorage.setItem("access_token", access_token);
        if (status === "success") {
          history.push("/dashboard");
        }
      })
      .catch((error: any) => {
        // <ErrorToast error={error} />;
        if (error.response) {
          dispatch(closeLoader());
          const message = error?.response?.data?.message;

          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        } else if (error.request) {
          dispatch(closeLoader());
        } else {
          dispatch(closeLoader());
        }
      });
  };

  const resendOtp = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post("/subsidiary/dashboard/otp-resend", {
        identifier: subsidiaryInfo.email,
        login_hash: loginHash,
      })
      .then((data: any) => {
        dispatch(closeLoader());
        const { message } = data?.data;
        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastSuccessStyles,
          })
        );
      })
      .catch((error: any) => {
        // <ErrorToast error={error} />;
        if (error.response) {
          dispatch(closeLoader());
          const message = error?.response?.data?.message;

          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        } else if (error.request) {
          dispatch(closeLoader());
        } else {
          dispatch(closeLoader());
        }
      });
  };

  return (
    <div className={styles.signInBody}>
      <div className={styles.logo}>
        <img src={Logo} width={207} height={56} alt="pay-invert" />
      </div>
      <div className={styles.signinForm}>
        <div className={styles.signIn}>Complete sign in</div>
        <div className={styles.businessForm}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="otp"
              inputLabel="OTP"
              inputValue={inputs.otp}
            />

            <div onClick={handleSubmit} className={styles.signInButton}>
              <SignInIcon />
              <span>Sign in</span>
            </div>
          </form>
        </div>
      </div>
      <div className={styles.forgotPassword} onClick={resendOtp}>
        Resend OTP
      </div>

      <div className={styles.sidebarFooter}>
        <div>Copyright &#169; Payinvert {todaysDate.getFullYear()}.</div>
        <div>All rights reserved</div>
      </div>
    </div>
  );
};

export default TwoFaSignin;
