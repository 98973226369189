import React, { useState, Fragment, useEffect } from "react";
import styles from "./Virtualwallets.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import TableHeader from "../../components/TableHeader/TableHeader";
import {
  ColumnVirtualWallets,
  VirtualWalletsData,
} from "../../types/TablesTypes/VirtualWallet";
import ExportButton from "../../components/ExportButton/ExportButton";
import virtualWalletsApi from "../../api/virtualWallets";
import useApi from "../../hooks/useApi";
import { ReactComponent as TopUp } from "../../assets/images/Icons/topup.svg";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import VirtualWalletModal from "../../components/ModalContent/VirtualWalletModal/VirtualWalletModal";

const PaymentLinks = () => {
  const [value, setValue] = useState("");
  const [tableRow, setTableRow] = useState<any[]>();
  const getVirtualWalletsApi = useApi(virtualWalletsApi.getVirtualWallets);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const fetchVirtualWallets = async () => {
    getVirtualWalletsApi.request();
  };

  useEffect(() => {
    fetchVirtualWallets();
  }, []);

  const dataPaymentLinks = () => {
    const tempArr: VirtualWalletsData[] = [];
    getVirtualWalletsApi?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((virtualWallet: any, index: number) => {
        tempArr.push({
          account_name: virtualWallet?.account_name,
          account_number: virtualWallet?.account_number,
          bank_name: virtualWallet?.bank_name,
          bvn: virtualWallet?.bvn,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [getVirtualWalletsApi?.data?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Virtual accounts"
        dataLength={getVirtualWalletsApi?.data?.items?.length}
        data={getVirtualWalletsApi?.data?.items}
        value={value}
        setValue={setValue}
        noShowFilter={true}
        newButton={
          <div className={styles.createLink} onClick={() => setIsOpen(true)}>
            <TopUp className={styles.link} />
            Create Virtual Wallet
          </div>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <VirtualWalletModal
          fetchVirtualWallets={fetchVirtualWallets}
          closeModal={closeModal}
        />
      </Modal>
      {getVirtualWalletsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          value={value}
          data={tableRow ? tableRow : []}
          columns={ColumnVirtualWallets ? ColumnVirtualWallets : []}
          emptyPlaceHolder="You currently do not have any virtual wallets"
          total={getVirtualWalletsApi?.data?.items?.length}
        />
      )}
    </Fragment>
  );
};

export default PaymentLinks;
