import React, { useEffect } from "react";
import styles from "./Preferences.module.scss";
import Radio from "@mui/material/Radio";
import Checkbox from "@mui/material/Checkbox";
import useApi from "../../hooks/useApi";
import bearsFeeApi from "../../api/bearsFee";
import paymentOptionsApi from "../../api/paymentOptions";
import twoFaOnLogin from "../../api/twoFaOnLogin";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../constants/toastStyles";

const Preferences = () => {
  const dispatch = useDispatch();
  const getBearsFeeApi = useApi(bearsFeeApi.getBearsFee);
  const getPaymentOptionsApi = useApi(paymentOptionsApi.getPaymentOptions);
  const getTwoFaOnLoginApi = useApi(twoFaOnLogin.getTwoFaOnLogin);

  const fetchBearsFee = async () => {
    const { data }: any = await client.get(
      "/subsidiary/dashboard/payment/fees/view"
    );
    setSelectedValue(!!data?.data && data?.data?.who_bears_fee);
  };

  const fetchTwoFaOnLogin = async () => {
    const { data }: any = await client.get(
      "/subsidiary/dashboard/2fa/login/view"
    );
    setCheckedTwoFa(!!data?.data && data?.data.is_two_fa_enabled);
  };

  const fetchPaymentOptions = async () => {
    const { data }: any = await client.get(
      "/subsidiary/dashboard/payment/options/view"
    );
    setCheckedBt(!!data?.data && data?.data?.includes("Bank Transfer"));
    setCheckedUssd(!!data?.data && data?.data?.includes("USSD"));
    setCheckedBank(!!data?.data && data?.data?.includes("Bank Account"));
    setCheckedCard(!!data?.data && data?.data?.includes("Card Payment"));
  };

  const apiCalls = async () => {
    await Promise.all([
      fetchBearsFee(),
      fetchPaymentOptions(),
      fetchTwoFaOnLogin(),
    ]);
  };
  useEffect(() => {
    apiCalls();
  }, []);

  const [selectedValue, setSelectedValue] = React.useState("");

  interface initTypes {
    Customer?: string;
    me?: string;
  }

  const initProps: initTypes = {
    Customer: "Customer",
    me: "me",
  };

  const setPaymentOption = async (check: any, option: string) => {
    if (!!check) {
      try {
        const data: any = await client.post(
          "/subsidiary/dashboard/payment/options/set",
          {
            payment_option: option,
          }
        );

        const message = data?.data?.message;
        fetchPaymentOptions();
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastSuccessStyles,
            toastContent: message,
          })
        );
      } catch (error: any) {
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastErrorStyles,
            toastContent: message,
          })
        );
      }
    } else {
      try {
        const data: any = await client.post(
          "/subsidiary/dashboard/payment/options/remove",
          {
            payment_option: option,
          }
        );

        const message = data?.data?.message;
        fetchPaymentOptions();
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastSuccessStyles,
            toastContent: message,
          })
        );
      } catch (error: any) {
        const { message } = error?.response?.data;
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastErrorStyles,
            toastContent: message,
          })
        );
      }
    }
  };

  const setTwoFa = async (check: any) => {
    try {
      const data: any = await client.post(
        "/subsidiary/dashboard/2fa/login/set",
        {
          two_fa: !!check ? "yes" : "no",
        }
      );

      const message = data?.data?.message;
      fetchTwoFaOnLogin();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const [checkedCard, setCheckedCard] = React.useState(false);
  const handleChangeCard = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedCard(event.target.checked);
    setPaymentOption(
      event.target.checked,
      event.target.checked ? "Card Payment" : "Card Payment"
    );
  };

  const [checkedBank, setCheckedBank] = React.useState(false);
  const handleChangeBank = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedBank(event.target.checked);
    setPaymentOption(
      event.target.checked,
      event.target.checked ? "Bank Account" : "Bank Account"
    );
  };

  const [checkedUssd, setCheckedUssd] = React.useState(false);
  const handleChangeUssd = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedUssd(event.target.checked);
    setPaymentOption(
      event.target.checked,
      event.target.checked ? "USSD" : "USSD"
    );
  };

  const [checkedBt, setCheckedBt] = React.useState(false);
  const handleChangeBt = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedBt(event.target.checked);
    setPaymentOption(
      event.target.checked,
      event.target.checked ? "Bank Transfer" : "Bank Transfer"
    );
  };

  const [checkedTwoFa, setCheckedTwoFa] = React.useState(false);
  const handleChangeTwoFa = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCheckedTwoFa(event.target.checked);
    setTwoFa(event.target.checked);
  };

  const [inputs, setInputs] = React.useState(initProps);
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const label = { inputProps: { "aria-label": "Checkbox demo" } };

  return (
    <div className={styles.preferencesBody}>
      <div className={styles.accounts}>
        <div className={styles.accountInfo}>Transactions</div>
        <div className={styles.accountEarnings}>
          Who should be charged transation fees
        </div>
        <div className={styles.radioSelection_one}>
          <Radio
            checked={selectedValue === inputs.me}
            onChange={handleChange}
            value={inputs.me}
            name="me"
            inputProps={{ "aria-label": inputs.me }}
            size="small"
          />
          <div className={styles.radioLabel}>
            Charge me the transaction fees
          </div>
        </div>
        <div className={styles.radioSelection}>
          <Radio
            checked={selectedValue === inputs.Customer}
            onChange={handleChange}
            value={inputs.Customer}
            name="Customer"
            inputProps={{ "aria-label": inputs.Customer }}
            size="small"
          />
          <div className={styles.radioLabel}>
            Charge my customers the transaction fees{" "}
          </div>
        </div>
      </div>

      <div className={styles.paymentMethods}>
        <div className={styles.accountInfo}>Transactions</div>
        <div className={styles.accountEarnings}>
          Who should be charged transation fees
        </div>
        <div className={styles.paymentList}>
          <div className={styles.checkboxContent}>
            <Checkbox
              checked={checkedCard}
              onChange={handleChangeCard}
              inputProps={{ "aria-label": "controlled" }}
            />
            <div className={styles.checkboxLabel}>Enable card payment</div>
          </div>
          <div className={styles.checkboxContent}>
            <Checkbox
              {...label}
              checked={checkedBank}
              onChange={handleChangeBank}
              inputProps={{ "aria-label": "controlled" }}
            />
            <div className={styles.checkboxLabel}>Enable bank payment</div>
          </div>
          <div className={styles.checkboxContent}>
            <Checkbox
              checked={checkedUssd}
              onChange={handleChangeUssd}
              inputProps={{ "aria-label": "controlled" }}
            />
            <div className={styles.checkboxLabel}>Enable USSD</div>
          </div>
          <div className={styles.checkboxContent}>
            <Checkbox
              checked={checkedBt}
              onChange={handleChangeBt}
              inputProps={{ "aria-label": "controlled" }}
            />
            <div className={styles.checkboxLabel}>Enable bank transfer</div>
          </div>
        </div>
      </div>

      <div className={styles.paymentMethods}>
        <div className={styles.accountInfo}>Other preferences</div>
        <div className={styles.paymentList}>
          <div className={styles.checkboxContentFa}>
            <Checkbox
              checked={checkedTwoFa}
              onChange={handleChangeTwoFa}
              inputProps={{ "aria-label": "controlled" }}
            />
            <div className={styles.checkboxLabel}>
              Enable Two Factor Authentication for login
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Preferences;
