import React from "react";

export interface SubsidiariesData {
  name: string;
  support_email: string;
  country: string;
  business_type: string;
}

export const ColumnSubsidiaries = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Email",
    accessor: "support_email",
  },
  {
    Header: "Country",
    accessor: "country",
  },
  {
    Header: "Business Type",
    accessor: "business_type",
  },
];
