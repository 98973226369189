import React, { useState, Fragment, useEffect } from "react";
import styles from "./Balancehistory.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  BalanceHistoryData,
  ColumnBalanceHistory,
} from "../../types/TablesTypes/balanceHistory";
import TableHeader from "../../components/TableHeader/TableHeader";
import MenuOption from "../../components/MenuOption/MenuOption";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import CopyIcon from "../../assets/images/Icons/copy.svg";
import balanceHistoryApi from "../../api/balanceHistory";
import useApi from "../../hooks/useApi";
import { numberWithCommas } from "../../utils/formatNumber";
import { useDispatch } from "react-redux";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import PaymentLinkMenu from "../../components/MenuOption/PaymentLinkMenu/PaymentLinkMenu";
import CreatePaymentLink from "../../components/ModalContent/CreatePaymentLink/CreatePaymentLink";
import moment from "moment";
import { useParams } from "react-router-dom";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import BalanceMenu from "../../components/MenuOption/BalanceMenu/BalanceMenu";

const BalanceHistory = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const { id } = useParams<{ id: any }>();
  const [modalIsOpen, setIsOpen] = React.useState(false);
  const [customerDetails, setCustomerDetails] = useState<any>([]);

  const [direction, setDirection] = useState<string>("");
  const dispatch = useDispatch();

  function closeModal() {
    setIsOpen(false);
  }

  const fetchBalanceHistory = () => {
    dispatch(openLoader());
    client
      .get(
        `/subsidiary/dashboard/fetch/wallet/history?walletId=${id}&direction=${direction}`
      )
      .then((res: any) => {
        setCustomerDetails(res);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        if (error.response) {
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error.request) {
          console.log(error.request);
        } else if (error?.response?.data?.message === "Please login again") {
          sessionStorage.clear();
          window.location.href = "/";
        } else {
          console.log("Error", error.message);
        }
      });
  };
  useEffect(() => {
    fetchBalanceHistory();
  }, [direction]);
  const StatusView = ({ status, green, orange, red }: any) => {
    return (
      <div>
        {status === red ? (
          <div className={styles.declined}>Debit</div>
        ) : status === orange ? (
          <div className={styles.processing}>{orange}</div>
        ) : status === green ? (
          <div className={styles.completed}>Credit</div>
        ) : null}
      </div>
    );
  };

  const dataPaymentLinks = () => {
    const tempArr: BalanceHistoryData[] = [];
    customerDetails?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((balance: any, index: number) => {
        tempArr.push({
          date: moment(balance?.date_created).format("MMMM Do YYYY, h:mm a"),
          amount: `NGN ${numberWithCommas(balance?.amount)}`,
          direction: (
            <>
              <StatusView
                status={balance?.direction}
                green={"Credit"}
                red={"Debit"}
              />
            </>
          ),
          balance: `NGN ${numberWithCommas(balance?.balance)}`,
          details: balance?.remarks,
          action: <BalanceMenu data={balance} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [customerDetails?.data?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Balance History"
        data={customerDetails?.data?.items}
        dataLength={customerDetails?.data?.items?.length}
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Direction</div>
            <div className={styles.filterBody}>
              <div
                onClick={() => {
                  setDirection("C");
                }}
                style={{ cursor: "pointer" }}
              >
                Credit
              </div>
              <div
                onClick={() => setDirection("D")}
                style={{ cursor: "pointer" }}
              >
                Debit
              </div>
            </div>
          </div>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreatePaymentLink
          closeModal={closeModal}
          fetchPaymentLink={fetchBalanceHistory}
        />
      </Modal>
      {customerDetails.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={ColumnBalanceHistory ? ColumnBalanceHistory : []}
          emptyPlaceHolder="You currently do not have any balance history"
          total={customerDetails?.data?.items?.length}
        />
      )}
    </Fragment>
  );
};

export default BalanceHistory;
