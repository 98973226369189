export interface AuditData {
  name: string;
  role: string;
  email_address: string;
  action: string;
  activity_type: string;
  ip_address: string;
  date_created: any;
}

export const AuditColumn = [
  {
    Header: "Name",
    accessor: "name",
  },
  {
    Header: "Role",
    accessor: "role",
  },
  {
    Header: "Email Address",
    accessor: "email_address",
  },
  {
    Header: "Action",
    accessor: "action",
  },
  {
    Header: "Activity Type",
    accessor: "activity_type",
  },
  {
    Header: "IP Address",
    accessor: "ip_address",
  },
  {
    Header: "Date initiated",
    accessor: "date_created",
  },
];
