import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./bankdetails.module.scss";

import OutlineTextInput from "../../../components/input/OutlineTextInput";
import ImagePlaceholder from "../../../assets/images/Icons/image-placeholder.png";
import { ReactComponent as UploadIcon } from "../../../assets/images/Icons/upload-image.svg";
import { ReactComponent as SaveIcon } from "../../../assets/images/Icons/save.svg";
import { ReactComponent as ResetIcon } from "../../../assets/images/Icons/reset-password.svg";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  openLoader,
  closeLoader
} from "../../../redux/actions/loader/loaderActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../../constants/toastStyles";
import { useDispatch, useSelector } from "react-redux";
import client from "../../../api/client";
import banksApi from "../../../api/banks";
import useApi from "../../../hooks/useApi";
import Select from "react-select";

type SelectionProp = {
  selection?: string;
  setSelection: Dispatch<SetStateAction<string>>;
  businessType: string;
};
function BankDetails({ selection, setSelection, businessType }: SelectionProp) {
  const dispatch = useDispatch();

  const getBanksApi = useApi(banksApi.getBanks);

  const [userBankDetails, setUserBankDetails] = useState<any>(null);

  const fetchBanks = async () => {
    await getBanksApi.request();
  };

  interface initTypes {
    account_number: string;
    account_name: string;
    bank_id: string;
  }

  const initProps: initTypes = {
    account_number: "",
    account_name: "",
    bank_id: ""
  };

  const [inputs, setInputs] = React.useState(initProps);

  const fetchBankDetails = async () => {
    dispatch(openLoader());
    try {
      const response: any = await client.get(
        `/subsidiary/dashboard/settlement/bank/get`
      );

      const bankDetails = response?.data?.data[0];
      setUserBankDetails(bankDetails);
      setSelectedBanks({ label: bankDetails?.bank_name });
      setInputs({
        account_number: bankDetails?.account_number,
        account_name: bankDetails?.account_name,
        bank_id: bankDetails?.id
      });
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;

      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  const [selectedBanks, setSelectedBanks] = React.useState<any>();

  let selectedBanksOptions = getBanksApi?.data?.banks?.map(function (
    bank: any
  ) {
    return {
      value: bank.id,
      label: bank.name
    };
  });

  let defaultBank = selectedBanksOptions?.filter(function (item: any) {
    return (
      item?.label.trim().toLowerCase() ===
      userBankDetails?.bank_name.trim().toLowerCase()
    );
  });

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());

    client
      .post("/subsidiary/dashboard/bank/details", {
        ...inputs,
        bank_id: !!selectedBanks?.value
          ? selectedBanks?.value
          : defaultBank?.[0]?.value
      })
      .then((data: any) => {
        dispatch(closeLoader());
        businessType.toLowerCase() === "company"
          ? setSelection("Information")
          : setSelection("Kyc");
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles
          })
        );
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles
          })
        );
      });
  };

  useEffect(() => {
    fetchBanks();
    fetchBankDetails();
  }, []);
  return (
    <div className={styles.business}>
      <div className={styles.businessCard} style={{ marginTop: "30px" }}>
        <div className={styles.businessForm}>
          <form className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="account_number"
              inputLabel="Account number"
              inputValue={inputs?.account_number}
            />
            <div>
              <label className={styles.inputLabel}>Banks</label>
              <Select
                defaultValue={selectedBanks}
                onChange={setSelectedBanks}
                options={selectedBanksOptions}
                placeholder={selectedBanks?.label}
                // styles={customStyles}
                className={styles.select}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 }),
                  placeholder: (provided) => ({
                    ...provided,
                    color: "#000000"
                  })
                }}
              />
            </div>

            {/* <OutlineTextInput
              inputName="bank_id"
              inputLabel="Bank Name"
              inputSelect
              inputOption={["One-off", "Product", "Subscription"]}
              handleChange={updateProps}
              inputValue={inputs.bank_id}
            /> */}
            <OutlineTextInput
              handleChange={updateProps}
              inputName="account_name"
              inputLabel="Account name"
              inputValue={inputs?.account_name}
              disabled={true}
            />
          </form>
          <div
            className={styles.uploadButton}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            <SaveIcon /> <span>Save and Continue</span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BankDetails;
