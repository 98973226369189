import React from "react";

export interface VirtualWalletsData {
  account_name: string;
  account_number: string;
  bank_name: string;
  bvn: string;
}

export const ColumnVirtualWallets = [
  {
    Header: "Account Name",
    accessor: "account_name",
  },
  {
    Header: "Account Number",
    accessor: "account_number",
  },
  {
    Header: "Bank Name",
    accessor: "bank_name",
  },
  {
    Header: "BVN",
    accessor: "bvn",
  },
];
