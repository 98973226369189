import React, { useEffect, useState } from "react";
import styles from "./Balances.module.scss";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card";
import BlockIcon from "../../assets/images/Icons/red-balance.svg";
import BalanceIcon from "../../assets/images/Icons/balance-card-icon.svg";
import BankIcon from "../../assets/images/Icons/bank-icon.svg";
import { ReactComponent as TopUp } from "../../assets/images/Icons/topup.svg";
import { ReactComponent as ViewIcon } from "../../assets/images/Icons/view.svg";
import balanceApi from "../../api/walletBalance";
import useApi from "../../hooks/useApi";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import TopUpModal from "../../components/ModalContent/TopUpModal/TopUpModal";
import { useHistory } from "react-router-dom";

const Balances = () => {
  const WalletBalanceApi = useApi(balanceApi.getWalletBalance);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const fetchWalletBalance = async () => {
    WalletBalanceApi.request();
  };
  useEffect(() => {
    fetchWalletBalance();
  }, []);

  const history = useHistory();

  const walletBalance = WalletBalanceApi?.data?.wallets[0];

  const availableBalance = walletBalance?.available_balance;
  const walletId = walletBalance?.wallet_id;
  const balanceChange = walletBalance?.available_balance_change;
  const ledgerBalance = walletBalance?.ledger_balance;
  const bankName = walletBalance?.account_details?.bank_name;
  const accountNumber = walletBalance?.account_details?.account_number;

  return (
    <div>
      <div className={styles.pageTop}>
        <div className={styles.actions}>
          {/* <div className={styles.topUp} onClick={() => setIsOpen(true)}>
            <TopUp className={styles.topIcon} /> Top Up
          </div> */}
          <div
            className={styles.viewBalance}
            onClick={() => {
              history.push(
                `/dashboard/account/balances/balance-history/${walletId}`
              );
            }}
          >
            <ViewIcon className={styles.topIcon} />
            View balance history
          </div>
        </div>
      </div>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <TopUpModal
          closeModal={closeModal}
          accountNumber={accountNumber}
          bankName={bankName}
        />
      </Modal>
      {WalletBalanceApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <Grid container spacing={2}
          style={{
            display: "flex",
            justifyContent: "end",
            flexDirection: "row",
          }}>
          <Grid item md={4} xs={12}>
            <Card
              icon={BalanceIcon}
              percentage={walletBalance && balanceChange}
              amount={walletBalance && availableBalance}
              transactionType="Available balance"
            />
          </Grid>
          <Grid item md={4} xs={12}>
            <Card
              icon={BlockIcon}
              amount={walletBalance && ledgerBalance}
              transactionType="Ledger Balance"
            />
          </Grid>
          {/* <Grid item md={4} xs={12}>
            <Card
              icon={BankIcon}
              amount={accountNumber}
              transactionType={bankName}
            />
          </Grid> */}
        </Grid>
      )}
    </div>
  );
};

export default Balances;
