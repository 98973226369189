import React, { useState, useEffect, Fragment } from "react";
import styles from "./Steptwo.module.scss";
import { ReactComponent as UploadIcon } from "../../../../assets/images/Icons/upload-image.svg";
import { ReactComponent as ResetIcon } from "../../../../assets/images/Icons/reset-password.svg";
import OutlineTextInput from "../../../../components/input/OutlineTextInput";
import { useDispatch, useSelector } from "react-redux";
import client from "../../../../api/client";
import {
  openLoader,
  closeLoader
} from "../../../../redux/actions/loader/loaderActions";
import { openToastAndSetContent } from "../../../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
  phoneInputStyle,
  constInputStyle,
  inputStyle
} from "../../../../constants/toastStyles";
import useApi from "../../../../hooks/useApi";
import currenciesApi from "../../../../api/currencies";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import Select from "react-select";
import { useHistory } from "react-router-dom";
import { numberWithCommas } from "../../../../utils/formatNumber";
import DefaultCurrency from "../../../../components/DefaultCurrency/DefaultCurrency";

const StepTwo = ({ setSteps, stepOneInputs }: any) => {
  const dispatch = useDispatch();
  const history = useHistory();

  interface initTypes {
    invoice_title: string;
    description: string;
    amount?: string;
  }

  const initProps: initTypes = {
    invoice_title: "",
    description: "",
    amount: ""
  };

  const [inputs, setInputs] = useState(initProps);
  const [tax, setTax] = useState("");
  const [startDate, setStartDate] = useState(new Date());
  const [discount, setDiscount] = useState("");


  const getCurrencies = useApi(currenciesApi.getCurrencies);
  const currencies = getCurrencies?.data?.data;

  const defaultCurrency = currencies?.find(
    (currency: any) => currency?.short_name === "NGN"
  )


  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  type itemProps = {
    amount: number;
    quantity: number;
    item: string;
  };

  const [items, setItems] = useState<itemProps[]>([
    { amount: 0, quantity: 0, item: "" }
  ]);

  let handleChange = (index: any, e: any) => {
    let newItems = [...items];
    if (e.target.name.startsWith("amount")) {
      newItems[index].amount = Number(e.target.value);
    } else if (e.target.name.startsWith("quantity")) {
      newItems[index].quantity = Number(e.target.value);
    } else {
      newItems[index].item = e.target.value;
    }
    setItems(newItems);
  };

  let addFormFields = () => {
    setItems([...items, { amount: 0, quantity: 0, item: "" }]);
  };

  let removeFormFields = (i: any) => {
    let newItems = [...items];
    newItems.splice(i, 1);
    setItems(newItems);
  };

  const fetchCurrencies = async () => {
    getCurrencies.request();
  };

  const totalItemsAmount = items
    .map((item) => item.amount * item.quantity)
    .reduce((prev, next) => prev + next);

  const totalAmount =
    !!discount && !!tax
      ? totalItemsAmount -
      Math.abs(parseInt(discount)) +
      Math.abs(parseInt(tax))
      : !discount && !!tax
        ? totalItemsAmount + Math.abs(parseInt(tax))
        : !discount && !tax
          ? totalItemsAmount
          : totalItemsAmount - Math.abs(parseInt(discount));

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchCurrencies();
  }, []);

  const payload = {
    invoice_title: inputs.invoice_title,
    description: inputs.description,
    amount: totalAmount,
    due_date: startDate,
    discount: discount,
    tax: tax,
    invoice_items: items,
    currency_id: defaultCurrency?.id,
    customer_info: {
      first_name: stepOneInputs.first_name,
      last_name: stepOneInputs.last_name,
      email_address: stepOneInputs.email_address,
      mobile_number: stepOneInputs.mobile_number
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post("/subsidiary/dashboard/invoice/create", payload)
      .then((data: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles
          })
        );
        history.push("/dashboard/business/invoice");
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        if (error?.response?.data?.message === "Please login again") {
          sessionStorage.clear();
          window.location.href = "/";
        } else {
          const message = error?.response?.data?.message;

          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles
            })
          );
        }
      });
  };
  return (
    <div className={styles.business}>
      <div className={styles.topSections}>
        <div className={styles.newInvoice}>New Invoice</div>
        <div className={styles.stepButton}>Step 2 of 2</div>
      </div>
      <div className={styles.businessCard} style={{ marginBottom: "30px" }}>
        <div className={styles.logoText}>Invoice details</div>
        <div className={styles.businessForm}>
          <form className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="invoice_title"
              inputLabel="Invoice title"
              inputValue={inputs.invoice_title}
            />
            <div>
              <div className={styles.inputLabel}>Due Date</div>
              <DatePicker
                selected={startDate}
                onChange={(date: Date) => setStartDate(date)}
                minDate={moment().toDate()}
                placeholderText="Select due date"
                wrapperClassName="datePicker"
                dateFormat="dd/MM/yyyy"
              />
            </div>
            <div>
              <label className={styles.inputLabel}>Currency</label>
              <DefaultCurrency currency={defaultCurrency?.name} />
            </div>
            {items.map((element, index) => (
              <Fragment>
                <div className={styles.itemLabel}>Item {index + 1}</div>
                <div className="form-inline" key={index}>
                  <label className={styles.inputLabel}>Item description</label>
                  <input
                    type="text"
                    name={`item${index}`}
                    value={element.item || ""}
                    onChange={(e) => handleChange(index, e)}
                    style={{ ...inputStyle, height: "4rem" }}
                  />
                  <div className={styles.businessType}>
                    <div style={{ width: "100%", marginTop: "27px" }}>
                      <label className={styles.inputLabel}>Unit price</label>
                      <input
                        type="text"
                        name={`amount${index}`}
                        value={element.amount || ""}
                        id={`amount${index}`}
                        onChange={(e) => handleChange(index, e)}
                        style={inputStyle}
                      />
                    </div>
                    <div style={{ marginTop: "27px" }}>
                      <label className={styles.inputLabel}>Quantity</label>
                      <input
                        type="text"
                        name={`quantity${index}`}
                        value={element.quantity || ""}
                        onChange={(e) => handleChange(index, e)}
                        style={inputStyle}
                      />
                    </div>
                  </div>

                  {index ? (
                    <div
                      className={styles.removeButton}
                      onClick={() => removeFormFields(index)}
                    >
                      Remove item
                    </div>
                  ) : null}
                </div>
              </Fragment>
            ))}
            <div>
              <div className={styles.newItem} onClick={() => addFormFields()}>
                Add another item
              </div>
            </div>
          </form>
        </div>
      </div>

      <div className={styles.businessCard} style={{ marginTop: "30px" }}>
        <div className={styles.businessForm}>
          <form className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="description"
              inputLabel="Invoice notes"
              inputValue={inputs.description}
            />
            <div>
              <label className={styles.inputLabel}>Add discount</label>
              <div className={styles.phoneInput}>
                <input
                  type="text"
                  value={defaultCurrency?.short_name}
                  style={constInputStyle}
                />
                <input
                  type="text"
                  name="discount"
                  value={discount}
                  onChange={(e) => setDiscount(e.target.value)}
                  style={phoneInputStyle}
                />
              </div>
            </div>

            <div>
              <label className={styles.inputLabel}>Add tax</label>
              <div className={styles.phoneInput}>
                <input
                  type="text"
                  value={defaultCurrency?.short_name}
                  style={constInputStyle}
                />
                <input
                  type="text"
                  name="tax"
                  value={tax}
                  onChange={(e) => setTax(e.target.value)}
                  style={phoneInputStyle}
                />
              </div>
            </div>
          </form>
          <div className={styles.subTotal}>
            <div className={styles.text}>Subtotal</div>
            <div className={styles.amount}>
              {defaultCurrency?.short_name}{" "}
              {!!totalItemsAmount && numberWithCommas(totalItemsAmount)}
            </div>
          </div>
          <div className={styles.Total}>
            <div className={styles.text}>TOTAL</div>
            <div className={styles.amount}>
              {defaultCurrency?.short_name}{" "}
              {!!totalAmount && numberWithCommas(totalAmount)}
            </div>
          </div>
        </div>
      </div>

      <div className={styles.footButtons}>
        <div
          className={styles.backButton}
          onClick={() => {
            setSteps("one");
          }}
        >
          Back
        </div>
        <div
          className={styles.nextButton}
          onClick={(e) => {
            handleSubmit(e);
          }}
        >
          Send invoice
        </div>
      </div>
    </div>
  );
};

export default StepTwo;
