import React, { useEffect } from "react";
import styles from "./Createpaymentlink.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../../constants/toastStyles";
import Select from "react-select";

const CreatePaymentLink = ({ closeModal, fetchPaymentLink }: any) => {
  const dispatch = useDispatch();

  const [paymentTypes, setPaymentTypes] = React.useState<any>([]);
  interface initTypes {
    name: string;
    description: string;
    amount: number | undefined;
    limit?: number | undefined;
  }

  const initProps: initTypes = {
    name: "",
    description: "",
    amount: undefined,
    limit: undefined,
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (inputs.name) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const [selectedPaymentType, setSelectedPaymentType] = React.useState<any>();

  const fetchPaymentLinkTypes = async () => {
    try {
      const data: any = await client.get(
        "/subsidiary/dashboard/payment/link/types"
      );
      setPaymentTypes(data?.data?.payment_link_types);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  let selectedPaymentTypeOptions = paymentTypes.map(function (
    selectedPaymentType: any
  ) {
    return {
      value: selectedPaymentType.code,
      label: selectedPaymentType.payment_link_name,
    };
  });
  const createPaymentLink = async () => {
    try {
      const data: any = await client.post(
        "/subsidiary/dashboard/payment/link/new",
        { ...inputs, payment_type: selectedPaymentType.value }
      );

      const message = data?.data?.message;
      fetchPaymentLink();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  useEffect(() => {
    fetchPaymentLinkTypes();
  }, []);

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createPaymentLink();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>New payment link</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="name"
            inputLabel="Link name"
            inputValue={inputs.name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="description"
            inputLabel="Description"
            inputValue={inputs.description}
          />

          <div>
            <label className={styles.inputLabel}>Payment Type</label>
            <Select
              defaultValue={selectedPaymentType}
              onChange={setSelectedPaymentType}
              options={selectedPaymentTypeOptions}
              // styles={customStyles}
              className={styles.select}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 }),
              }}
            />
          </div>

          <OutlineTextInput
            handleChange={updateProps}
            inputName="amount"
            inputLabel="Amount"
            inputValue={inputs.amount}
          />

          <OutlineTextInput
            handleChange={updateProps}
            inputName="limit"
            inputLabel="Limit"
            inputValue={inputs.limit}
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Create new link
          </div>
        ) : (
          <div className={styles.notClickable}>Create new link</div>
        )}
      </div>
    </div>
  );
};

export default CreatePaymentLink;
