import React, { Dispatch, SetStateAction, useEffect } from "react";
import styles from "./information.module.scss";

import OutlineTextInput from "../../../components/input/OutlineTextInput";
import ImagePlaceholder from "../../../assets/images/Icons/image-placeholder.png";
import { ReactComponent as SaveIcon } from "../../../assets/images/Icons/save.svg";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  openLoader,
  closeLoader
} from "../../../redux/actions/loader/loaderActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../../constants/toastStyles";
import { useDispatch, useSelector } from "react-redux";
import client, { formClient } from "../../../api/client";

type SelectionProp = {
  selection?: string;
  setSelection: Dispatch<SetStateAction<string>>;
};
const Information = ({ selection, setSelection }: SelectionProp) => {
  interface initTypes {
    directorname: string;
    idnumber: string;
    id: string;
  }

  const dispatch = useDispatch();
  const initProps: initTypes = {
    directorname: "",
    idnumber: "",
    id: ""
  };
  const [inputs, setInputs] = React.useState(initProps);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };
  const updateImageProps = (event: any) => {
    const newValue = event?.target?.files[0];
    const inputName = event?.target?.name;
    setInputs((prevState: any) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const fetchDirectorDetails = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(
        `/subsidiary/dashboard/kyc/director/view`
      );
      const directorDetails = data?.data;
      setInputs({
        ...inputs,
        directorname: directorDetails?.name,
        idnumber: directorDetails?.identification
      });
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;

      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  let formData = new FormData();

  formData.append("directorname", inputs.directorname); //append the values with key, value pair
  formData.append("idnumber", inputs.idnumber);
  formData.append("id", inputs.id);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    formClient
      .post("/subsidiary/dashboard/kyc/director/submit", formData)
      .then((data: any) => {
        dispatch(closeLoader());
        setSelection("Kyc");
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles
          })
        );
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles
          })
        );
      });
  };

  useEffect(() => {
    fetchDirectorDetails();
  }, []);

  return (
    <div className={styles.business}>
      <div className={styles.businessCard} style={{ marginTop: "30px" }}>
        <div className={styles.logoText}>Director</div>
        <div className={styles.businessForm}>
          <form className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="directorname"
              inputLabel="Director’s full name"
              inputValue={inputs?.directorname}
            />
            <OutlineTextInput
              inputName="id"
              inputLabel="Director's ID"
              handleChange={updateImageProps}
              inputType="file"
              inputShrink
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="idnumber"
              inputLabel="ID number"
              inputValue={inputs?.idnumber}
            />
          </form>
          <div
            className={styles.uploadButton}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            <SaveIcon /> <span>Save and Continue</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Information;
