import React from "react";

export interface BalanceHistoryData {
  date: string;
  amount: string;
  details: string;
  balance: string;
  direction: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnBalanceHistory = [
  {
    Header: "Date",
    accessor: "date",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },
  {
    Header: "Direction",
    accessor: "direction",
  },
  {
    Header: "Balance",
    accessor: "balance",
  },
  {
    Header: "Details",
    accessor: "details",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
