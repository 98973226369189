import React, { useState, useEffect } from "react";
import styles from "./Transactiondetails.module.scss";
import { useParams, useHistory } from "react-router";
import axios from "axios";
import client from "../../api/client";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import moment from "moment";

const TransactionDetails = () => {
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const { id } = useParams<{ id: any }>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`subsidiary/dashboard/fetch/customers/orders?customers?id=${id}`)
      .then((res: any) => {
        setCustomerDetails(res?.data?.items[0]);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error?.response?.data?.message === "Please login again") {
          sessionStorage.clear();
          window.location.href = "/";
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  return (
    <div>
      {isLoading ? (
        <div />
      ) : (
        <>
          <div className={styles.transactionsHeader}>
            <div
              style={{ color: "#616161", display: "inline", cursor: "pointer" }}
              onClick={() => history.goBack()}
            >
              Transaction
            </div>{" "}
            {">"} Transaction details
          </div>
          <div className={styles.topTable}>
            <div className={styles.detailsHeader}>Order details</div>
            <div className={styles.tableContent}>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Order reference</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.order_reference}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Payment reference</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.payment_reference}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Payment response code</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.payment_response_code}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>
                  Payment response message
                </div>
                <div className={styles.detailsKey}>
                  {!!customerDetails?.payment_response_message
                    ? customerDetails?.payment_response_message
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
          <div className={styles.topTable}>
            <div className={styles.detailsHeader}>Customer Details</div>
            <div className={styles.tableContent}>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Customer Name</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.customer_name}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Customer email</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.customer_email}
                </div>
              </div>
              {/* <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Customer phone number</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.mobile_number}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Country</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.country_name}
                </div>
              </div> */}
            </div>
          </div>
          <div className={styles.topTable}>
            <div className={styles.detailsHeader}>Payment Details</div>
            <div className={styles.tableContent}>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Payment method</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.payment_type}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Currency</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.currency}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Amount</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.currency}{" "}
                  {customerDetails?.amount_formatted}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Status</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.order_status}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Narration</div>
                <div className={styles.detailsKey}>
                  {!!customerDetails?.narration
                    ? customerDetails?.narration
                    : "N/A"}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Remark</div>
                <div className={styles.detailsKey}>
                  {!!customerDetails?.remarks
                    ? customerDetails?.remarks
                    : "N/A"}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Fee</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.fee_formatted}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Subsidiary Fee</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.subsidiary_fee_formatted}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Customer Fee</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.custom_fee_formatted}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Who bears fee?</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.who_bears_fee}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Date created</div>
                <div className={styles.detailsKey}>
                  {moment(customerDetails?.date_created).format(
                    "MMMM Do YYYY, h:mm a"
                  )}
                </div>
              </div>

              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Date updated</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.date_updated
                    ? moment(customerDetails?.date_updated).format(
                        "MMMM Do YYYY, h:mm a"
                      )
                    : "N/A"}
                </div>
              </div>

              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>
                  Date payment confirmed
                </div>
                <div className={styles.detailsKey}>
                  {customerDetails?.date_payment_confirmed
                    ? moment(customerDetails?.date_payment_confirmed).format(
                        "MMMM Do YYYY, h:mm a"
                      )
                    : "N/A"}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default TransactionDetails;
