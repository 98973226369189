import React, { useState, Fragment, useEffect } from "react";
import styles from "./Products.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import TableHeader from "../../components/TableHeader/TableHeader";
import { ColumnProducts, ProductsData } from "../../types/TablesTypes/Products";
import StatusView from "../../components/StatusView/StatusView";
import productsApi from "../../api/products";
import useApi from "../../hooks/useApi";
import { ReactComponent as TopUp } from "../../assets/images/Icons/topup.svg";
import { numberWithCommas } from "../../utils/formatNumber";
import { useHistory } from "react-router-dom";
import ProductMenu from "../../components/MenuOption/ProductMenu/ProductMenu";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";

const PaymentLinks = () => {
  const dispatch = useDispatch();
  const [products, setProducts] = useState<any>();
  const [availability, setAvailability] = useState<boolean>();
  const history = useHistory();
  const [value, setValue] = useState("");
  const [tableRow, setTableRow] = useState<any[]>();
  const getProductsApi = useApi(productsApi.getProducts);

  const fetchProducts = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `/subsidiary/dashboard/product/all?availability=${availability}`
      );
      setProducts(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.status_code === 401) {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchProducts();
  }, [availability]);

  const dataPaymentLinks = () => {
    const tempArr: ProductsData[] = [];
    products?.items
      ?.slice(0)
      .reverse()
      .forEach((product: any, index: number) => {
        tempArr.push({
          productName: (
            <div className={styles.productContent}>
              <img
                src={product?.image}
                alt={product?.name}
                width={64}
                height={64}
                className={styles.productImg}
              />
              <div className={styles.productName}>{product?.name}</div>
            </div>
          ),
          price: `NGN ${numberWithCommas(product?.price)}`,
          availability: (
            <StatusView
              status={product?.availability}
              green="In-stock"
              red="Out-of-stock"
            />
          ),
          stockCount: product?.stock,
          sold: product?.total_orders,
          revenue: `NGN ${numberWithCommas(product?.total_amount)}`,
          action: (
            <ProductMenu
              fetchProducts={fetchProducts}
              productId={product?.id}
              status={product?.status}
            />
          )
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [products?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Products"
        dataLength={products?.items?.length}
        data={products?.items}
        value={value}
        setValue={setValue}
        newButton={
          <div
            className={styles.createLink}
            onClick={() => {
              history.push("products/new-product");
            }}
          >
            <TopUp className={styles.link} />
            Add New Product
          </div>
        }
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Status</div>
            <div className={styles.filterBody}>
              <div
                onClick={() => {
                  setAvailability(true);
                }}
                style={{ cursor: "pointer" }}
              >
                In Stock
              </div>
              <div
                onClick={() => setAvailability(false)}
                style={{ cursor: "pointer" }}
              >
                Out of Stock
              </div>
            </div>
          </div>
        }
      />
      {getProductsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          value={value}
          columns={ColumnProducts ? ColumnProducts : []}
          emptyPlaceHolder="You do not have any products"
          total={products?.items?.length}
        />
      )}
    </Fragment>
  );
};

export default PaymentLinks;
