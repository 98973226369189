import React, { useState, useEffect } from "react";
import styles from "./Business.module.scss";
import ImagePlaceholder from "../../assets/images/Icons/image-placeholder.png";
import { ReactComponent as UploadIcon } from "../../assets/images/Icons/upload-image.svg";
import { ReactComponent as SaveIcon } from "../../assets/images/Icons/save.svg";
import { ReactComponent as ResetIcon } from "../../assets/images/Icons/reset-password.svg";
import OutlineTextInput from "../../components/input/OutlineTextInput";
import { useSelector, useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import countriesApi from "../../api/countries";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../constants/toastStyles";
import client, { formClient } from "../../api/client";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import useApi from "../../hooks/useApi";
import industriesApi from "../../api/industries";

const Business = () => {
  const dispatch = useDispatch();

  interface initTypes {
    business_name: string;
    business_type: string;
    support_email: string;
    description: string;
    legal_business_name: string;
    name: string;
    new_password: string;
    old_password: string;
    phone: string;
    avatar: any;
    industry: any;
    newAvatar: any;
    country: string;
  }

  const initProps: initTypes = {
    business_name: "",
    business_type: "",
    support_email: "",
    description: "",
    phone: "",
    legal_business_name: "",
    name: "",
    new_password: "",
    avatar: "",
    old_password: "",
    industry: "",
    country: "",
    newAvatar: ""
  };

  const [inputs, setInputs] = React.useState(initProps);
  const [imageView, setImageView] = useState<any>(null);

  const [defaultSub, setDefaultSub] = useState<any>();
  const getMe = async () => {
    try {
      const data: any = await client.get("/subsidiary/dashboard/me");
      setDefaultSub(data?.data);
      const subDetails = data?.data?.subsidiary_details?.subsidiaries[0];

      setInputs({
        business_name: subDetails?.business_name,
        business_type: subDetails?.business_type,
        support_email: subDetails?.support_email,
        description: subDetails?.description,
        phone: subDetails?.mobile_number,
        legal_business_name: subDetails?.legal_business_name,
        name: subDetails?.name,
        new_password: "",
        avatar: subDetails?.subsidiary_logo,
        old_password: "",
        industry: subDetails?.industry,
        country: subDetails?.country,
        newAvatar: ""
      });
      // dispatch(saveSubsidiaryInfo(data?.data));
    } catch (error: any) {
      if (error?.response?.data?.status_code === 401) {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };
  const getIndustriesApi = useApi(industriesApi.getIndustries);
  const getCountriesApi = useApi(countriesApi.getCountries);

  const fetchCountries = async () => {
    await getCountriesApi.request();
  };

  const fetchIndustries = async () => {
    getIndustriesApi.request();
  };


  const countryData = getCountriesApi?.data?.data;
  const filteredCountry = countryData?.find((country: { name: any; }) => country?.name === inputs?.country);


  useEffect(() => {
    getMe();
    fetchIndustries();
    fetchCountries()
  }, []);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };
  const updateImageProps = (event: any) => {
    const newValue = event?.target?.files[0];
    if (newValue) {
      const reader = new FileReader();

      reader.onloadend = () => {
        setImageView(reader.result);
      };

      reader.readAsDataURL(newValue);
    }
    const inputName = event?.target?.name;
    setInputs((prevState: any) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const updateBusinessPayload = {
    business_Name: inputs?.business_name,
    business_Email: inputs?.support_email,
    description: inputs?.description,
    business_Type: inputs?.business_type,
    legal_Business_Name: inputs?.legal_business_name,
    industry_sector: inputs?.industry,
    industry: inputs?.industry,
    country: filteredCountry?.short_name
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post("/subsidiary/dashboard/business/update", updateBusinessPayload)
      .then((data: any) => {
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles
          })
        );
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles
          })
        );
      });
  };

  if (getIndustriesApi.loading) {
    return <div className={styles.loading} />;
  }

  const data = getIndustriesApi?.data?.data;

  const industries = data?.map((item: any) => item.name);

  const createPaymentLink = async () => {
    let formData = new FormData();
    formData.append("logo", inputs?.avatar);
    dispatch(openLoader());
    try {
      const data: any = await formClient.post(
        "/subsidiary/dashboard/business/logos/add-or-update",
        formData
      );

      const message = data?.data?.message;
      // input?.avatar = "";
      setInputs((prevState: any) => {
        return {
          ...prevState,
          avatar: ""
        };
      });
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      dispatch(closeLoader());
      setInputs((prevState: any) => {
        return {
          ...prevState,
          avatar: null
        };
      });
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const changePassword = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post(
        "/subsidiary/dashboard/change-password",
        {
          new_password: inputs?.new_password,
          old_password: inputs?.old_password
        }
      );

      const message = data?.data?.message;
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  return (
    <div className={styles.business}>
      <div className={styles.profileCard}>
        <div className={styles.logoText}>Avatar</div>

        <div className={styles.imagePlaceholder}>
          <img
            src={
              inputs?.newAvatar ? imageView : inputs?.avatar
            }
            alt="logo-placeholder"
            width={100}
            height={100}
            style={{ borderRadius: "8px" }}
          />
        </div>
        {inputs?.newAvatar ? (
          <div className={styles.uploadButton} onClick={createPaymentLink}>
            <SaveIcon /> <span>Upload Logo</span>
          </div>
        ) : (
          <div className={styles.uploadButton}>
            <UploadIcon />

            <input
              type="file"
              name="newAvatar"
              // value={inputs?.avatar}
              onChange={updateImageProps}
            />
          </div>
        )}
      </div>
      <div className={styles.businessCard} style={{ marginTop: "30px" }}>
        <div className={styles.logoText}>Business information</div>
        <div className={styles.businessForm}>
          <form className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="business_name"
              inputLabel="Business name"
              inputValue={inputs?.business_name}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="support_email"
              inputLabel="Business email"
              inputValue={inputs?.support_email}
            />

            <OutlineTextInput
              inputName="business_type"
              inputLabel="Business type"
              inputValue={inputs?.business_type}
              inputSelect
              inputOption={["Individual", "Company"]}
            />
            {/* <OutlineTextInput
              inputName="payment_type"
              inputLabel="Country"
              inputSelect
              inputOption={["One-off", "Product", "Subscription"]}
            /> */}
            {/* <OutlineTextInput
              inputName="payment_type"
              inputLabel="Industry"
              inputSelect
              inputOption={["One-off", "Product", "Subscription"]}
            /> */}
            <OutlineTextInput
              handleChange={updateProps}
              inputName="legal_business_name"
              inputLabel="Legal business name"
              inputValue={inputs?.legal_business_name}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="description"
              inputLabel="Description"
              multiline
              inputValue={inputs?.description}
            />
            <OutlineTextInput
              inputName="industry"
              inputLabel="Industry"
              inputValue={inputs?.industry}
              inputSelect
              inputOption={industries}
              handleChange={updateProps}
            />
          </form>
          <div className={styles.uploadButton} onClick={handleSubmit}>
            <SaveIcon /> <span>Save changes</span>
          </div>
        </div>
      </div>

      <div className={styles.businessCard} style={{ marginTop: "30px" }}>
        <div className={styles.logoText}>Password</div>
        <div className={styles.subText}>
          If you want to change your password, we’ll help you out.
        </div>
        <div className={styles.businessForm}>
          <form className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="old_password"
              inputLabel="Old Password"
              inputType="Password"
              inputValue={inputs.old_password}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="new_password"
              inputLabel="New Password"
              inputType="Password"
              inputValue={inputs.new_password}
            />
          </form>
          <div className={styles.uploadButton} onClick={changePassword}>
            <ResetIcon /> <span>Reset password</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Business;
