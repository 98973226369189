import React from "react";
import styles from "./Virtualwalletmodal.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../../constants/toastStyles";
import { closeLoader, openLoader } from "../../../redux/actions/loader/loaderActions";

const VirtualWalletModal = ({ closeModal, fetchVirtualWallets }: any) => {
  const dispatch = useDispatch();
  interface initTypes {
    // first_name: string;
    // email: string;
    // last_name: string;
    // mobile_number: string;
    bvn?: string;
  }

  const initProps: initTypes = {
    // first_name: "",
    // email: "",
    // last_name: "",
    // mobile_number: "+234",
    bvn: ""
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (
      // inputs.first_name &&
      // inputs.email &&
      // inputs.last_name &&
      // inputs.mobile_number &&
      inputs.bvn
    ) {
      return true;
    }
  };
  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    if (inputName === "bvn") {
      /^[0-9]*$/.test(newValue) &&
        setInputs((prevState: initTypes) => {
          return {
            ...prevState,
            [inputName]: newValue
          };
        });
    } else {
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue
        };
      });
    }
  };

  const createVirtualWallet = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post(
        "/subsidiary/create/static/account",
        inputs
      );

      const message = data?.data?.message;
      dispatch(closeLoader());
      fetchVirtualWallets();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    createVirtualWallet();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>New virtual account</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          {/* <OutlineTextInput
            handleChange={updateProps}
            inputName="first_name"
            inputLabel="First name"
            inputValue={inputs.first_name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="last_name"
            inputLabel="Last name"
            inputValue={inputs.last_name}
          />
          <OutlineTextInput
            handleChange={updateProps}
            inputName="email"
            inputLabel="Email address"
            inputValue={inputs.email}
          />

          <OutlineTextInput
            handleChange={updateProps}
            inputName="mobile_number"
            inputLabel="Mobile Number"
            inputValue={inputs.mobile_number}
          /> */}

          <OutlineTextInput
            handleChange={updateProps}
            inputName="bvn"
            inputLabel="BVN"
            inputValue={inputs.bvn}
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Create new wallet
          </div>
        ) : (
          <div className={styles.notClickable}>Create new wallet</div>
        )}
      </div>
    </div>
  );
};

export default VirtualWalletModal;
