import React from "react";
import styles from "./Statusview.module.scss";

type StausProps = {
  status: string;
  green?: string;
  red?: string;
  orange?: string;
  style?: any
};
const StatusView = ({ status, green, orange, red, style }: StausProps) => {
  return (
    <div style={style}>
      {status === red ? (
        <div className={styles.declined}>{red}</div>
      ) : status === orange ? (
        <div className={styles.processing}>{orange || "N/A"}</div>
      ) : status === green ? (
        <div className={styles.completed}>{green}</div>
      ) : null}
    </div>
  );
};

export default StatusView;
