import React, { useState, useEffect } from "react";
import styles from "./Navbar.module.scss";
import Switch from "react-switch";
import { ReactComponent as BellIcon } from "../../assets/images/Icons/bell.svg";
import UserImage from "../../assets/images/Logos/user-image.png";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../constants/toastStyles";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import moment from "moment";

const NavBar = () => {
  const dispatch = useDispatch();

  const [envDetails, setEnvDetails] = useState<any>([]);
  const [notification, setNotifications] = useState([]);
  const [checked, setChecked] = useState<boolean>(false);

  const [dropdown, setDropdown] = useState(false);
  const handleClickAway = () => {
    setDropdown(false);
  };

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const getMe = async () => {
    try {
      const data: any = await client.get("/subsidiary/dashboard/me");

      setEnvDetails(data?.data?.envronment_detail);

      setChecked(data?.data?.environment_detail?.id === 11 ? true : false);
      setNotifications(data?.data?.notifications);
    } catch (error: any) {
      if (error?.response?.data?.status_code === 401) {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  const toggleEnv = async (value: boolean) => {
    try {
      const data: any = await client.patch(
        "subsidiary/dashboard/environment/toggle",
        {
          environment_id: value === true ? 1 : 2,
          set_as_default: true
        }
      );
      setChecked(!checked);
      getMe();
    } catch (error: any) {
      if (error?.response?.data?.status_code === 401) {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  const handleChecked = () => {
    toggleEnv(checked);
  };

  useEffect(() => {
    getMe();
  }, []);
  const { navbarRoute } = useSelector((state) => state.navbarReducer);
  const { subsidiaryInfo } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );

  const subsidiary_details = subsidiaryInfo?.data?.subsidiary_details;

  return (
    <div className={styles.navBar}>
      {!navbarRoute ? (
        <div className={styles.navBarRoute}>Dashboard</div>
      ) : (
        <div className={styles.navBarRoute}>{navbarRoute} </div>
      )}
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.activitySection}>
          <div className={styles.envName}>{envDetails?.name}</div>
          <Switch
            checked={checked}
            onChange={handleChecked}
            offColor="#757575"
            height={24}
            width={48}
            uncheckedIcon={false}
            checkedIcon={false}
            onColor="#19943C"
          />
          <div className={styles.notification} onClick={handleDropdown}>
            <BellIcon />
            {notification?.length > 0 && (
              <div className={styles.notificationCount}>
                {notification?.length}
              </div>
            )}
          </div>
          {notification?.length > 0 && dropdown && (
            <div className={styles.exportDropdown}>
              {notification?.length > 0 &&
                notification?.map((item: any) => (
                  <div
                    style={{
                      width: "100%",
                      borderBottom: "1px solid #ededed",
                      paddingBottom: "5px",
                      paddingTop: "5px"
                    }}
                  >
                    <div className={styles.notificationTop}>
                      <div className={styles.messageFrom}>
                        {item?.message_from}
                      </div>
                      <div className={styles.date}>
                        {moment(item?.date_created, "YYYYMMDD").fromNow()}
                      </div>
                    </div>
                    <div key={item?.id} className={styles.messageText}>
                      {item?.message_text}
                    </div>
                  </div>
                ))}
            </div>
          )}
          {subsidiary_details.subsidiaries[0].subsidiary_logo && (
            <img
              src={
                subsidiary_details.subsidiaries[0].subsidiary_logo &&
                subsidiary_details.subsidiaries[0].subsidiary_logo
              }
              height={40}
              width={40}
              className={styles.userImage}
              alt="user"
            />
          )}
        </div>
      </ClickAwayListener>
    </div>
  );
};

export default NavBar;
