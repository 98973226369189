import React, { useEffect } from "react";
import styles from "./Initiatepayout.module.scss";
import IconButton from "@mui/material/IconButton";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import OutlineTextInput from "../../input/OutlineTextInput";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../../constants/toastStyles";
import Select from "react-select";
import {
  closeLoader,
  openLoader
} from "../../../redux/actions/loader/loaderActions";
import DefaultCurrency from "../../DefaultCurrency/DefaultCurrency";

const InitiatePayout = ({ closeModal, fetchPayouts }: any) => {
  const dispatch = useDispatch();
  const [banks, setBanks] = React.useState<any>([]);
  const [selectedBank, setSelectedBank] = React.useState<any>();

  const [currency, setCurrency] = React.useState<any>([]);
  const [selectedCurrency, setSelectedCurrency] = React.useState<any>();

  const [accountName, setAccountName] = React.useState("");

  const fetchBanks = async () => {
    try {
      const data: any = await client.get("/subsidiary/dashboard/banks");
      setBanks(data?.data?.banks);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  let selectedBanksOptions = banks?.map(function (bank: any) {
    return {
      value: bank.id,
      label: bank.name,
      code: bank.bank_code
    };
  });

  const fetchCurrencies = async () => {
    try {
      const data: any = await client.get(
        "/subsidiary/dashboard/service/currencies"
      );
      setCurrency(data?.data?.data);
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };


  const defaultCurrency = currency?.find(
    (currency: any) => currency?.short_name === "NGN"
  )


  interface initTypes {
    account_number: number | undefined;
    description: string;
    amount: number | undefined;
  }

  const initProps: initTypes = {
    account_number: undefined,
    description: "",
    amount: undefined
  };
  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (
      inputs.account_number &&
      inputs.amount &&
      !!selectedBank
      // !!defaultCurrency
    ) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  useEffect(() => {
    fetchBanks();
    fetchCurrencies();
  }, []);
  const payload = {
    ...inputs,
    bank_code: selectedBank?.code,
    bank_id: selectedBank?.value,
    credit_currency: defaultCurrency?.short_name
  };

  const initiatePayout = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post("/subsidiary/payout/initiate", {
        payout_details: [payload]
      });
      const message = data?.data?.message;
      dispatch(closeLoader());
      fetchPayouts();
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  if (
    !!inputs?.account_number &&
    inputs?.account_number?.toString()?.length >= 10
  ) {
    const resolveAccount = async () => {
      dispatch(openLoader());
      try {
        const data: any = await client.post(
          "/subsidiary/payout/account/resolve",
          {
            account_number: inputs?.account_number,
            bank_code: selectedBank?.code
          }
        );
        setAccountName(data?.data?.data?.account_name);
        const message = data?.data?.message;
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastSuccessStyles,
            toastContent: message
          })
        );
      } catch (error: any) {
        const { message } = error?.response?.data;
        dispatch(closeLoader());
        dispatch(
          openToastAndSetContent({
            toastStyles: ToastErrorStyles,
            toastContent: message
          })
        );
      }
    };
    resolveAccount();
  }

  const handleSubmit = (e: any) => {
    e.preventDefault();
    initiatePayout();
  };
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>New payout</div>
          <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
        </div>
      </div>
      <div className={styles.businessForm}>
        <form onSubmit={handleSubmit} className={styles.form}>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="amount"
            inputLabel="Amount"
            inputValue={inputs.amount}
          />

          <div>
            <label className={styles.inputLabel}>Bank</label>
            <Select
              defaultValue={selectedBank}
              onChange={setSelectedBank}
              options={selectedBanksOptions}
              className={styles.select}
              styles={{
                // Fixes the overlapping problem of the component
                menu: (provided) => ({ ...provided, zIndex: 9999 })
              }}
            />
          </div>

          <OutlineTextInput
            handleChange={updateProps}
            inputName="account_number"
            inputLabel="Account number"
            inputValue={inputs.account_number}
          />
          {!!accountName && (
            <span className={styles.accountName}>{accountName}</span>
          )}

          <div>
            <label className={styles.inputLabel}>Currency </label>
            <DefaultCurrency currency={defaultCurrency?.name} />
          </div>
          <OutlineTextInput
            handleChange={updateProps}
            inputName="description"
            inputLabel="Description"
            inputValue={inputs.description}
            multiline={true}
          />
        </form>
      </div>
      <div className={styles.modalFooter}>
        <div className={styles.cancel} onClick={closeModal}>
          Cancel
        </div>
        {disableFunction() ? (
          <div className={styles.deactivate} onClick={handleSubmit}>
            Initiate payout
          </div>
        ) : (
          <div className={styles.notClickable}> Initiate payout</div>
        )}
      </div>
    </div>
  );
};

export default InitiatePayout;
