import React, { useState, Fragment, useEffect } from "react";
import styles from "./Transactions.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnTransactions,
  TransactionsData
} from "../../types/TablesTypes/Transactions";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import TransactionsMenu from "../../components/MenuOption/TransactionsMenu/TransactionsMenu";
import transactionsApi from "../../api/transactions";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { numberWithCommas } from "../../utils/formatNumber";
import moment from "moment";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";

const Transactions = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<string>("");
  const [value, setValue] = useState("");
  const [tableRow, setTableRow] = useState<any[]>();

  const [transactions, setTransactions] = useState<any>();

  const getTransactionsApi = useApi(transactionsApi.getTransactions);

  const fetchTransactions = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `/subsidiary/dashboard/fetch/orders?status=${status}`
      );
      setTransactions(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.status_code === 401) {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchTransactions();
  }, [status]);

  const dataTransaction = () => {
    const tempArr: TransactionsData[] = [];
    transactions?.items
      ?.slice(0)
      .reverse()
      .forEach((transaction: any, index: number) => {
        tempArr.push({
          date: moment(transaction?.date_created).format(
            "MMMM Do YYYY, h:mm a"
          ),
          email: transaction?.customer_email,
          amount: `NGN ${numberWithCommas(transaction?.settlement_amount)}`,
          channel: transaction?.payment_type,
          reference: transaction?.order_reference,
          status: (
            <StatusView
              status={transaction?.order_status}
              green="Successful"
              red="Awaiting-Confirmation"
              orange="Initiated"
            />
          ),
          action: (
            <TransactionsMenu
              transactionId={transaction?.id}
              fetchTransactions={fetchTransactions}
              amount={transaction?.settlement_amount}
              customerId={transaction?.customer_id}
              status={transaction?.order_status}
            />
          )
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [transactions?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Transactions"
        dataLength={transactions?.items?.length}
        data={transactions?.items}
        value={value}
        setValue={setValue}
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Status</div>
            <div className={styles.filterBody}>
              <div
                onClick={() => {
                  setStatus("Successful");
                }}
              >
                Successful
              </div>
              <div onClick={() => setStatus("Awaiting-Confirmation")}>
                Awaiting Confirmation
              </div>
              <div
                onClick={() => {
                  setStatus("Initiated");
                }}
              >
                Initiated
              </div>
            </div>
          </div>
        }
      />

      {getTransactionsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          value={value}
          columns={ColumnTransactions ? ColumnTransactions : []}
          emptyPlaceHolder="You currently do not have any transactions"
          total={transactions?.items?.length}
        />
      )}
    </Fragment>
  );
};

export default Transactions;
