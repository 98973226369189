import React, { Dispatch, useEffect, MouseEventHandler } from "react";
import styles from "./Paymentmodal.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import { numberWithCommas } from "../../../utils/formatNumber";

type PaymentProps = {
  closeModal: MouseEventHandler<SVGSVGElement>;
  reference: any;
};
const PaymentModal = ({ closeModal, reference }: PaymentProps) => {
  return (
    <div className={styles.modalBody}>
      <div className={styles.modalHeader}>
        <div className={styles.background}>
          <div className={styles.linkDetails}>Link details</div>
          <CloseIcon onClick={closeModal} />
        </div>
      </div>
      {reference && (
        <div className={styles.modalContent}>
          <div className={styles.modalDetails}>
            <div className={styles.link}>Link Name</div>
            <div className={styles.content}>{reference?.name}</div>
          </div>
          <div className={styles.modalDetails}>
            <div className={styles.link}>Link Type</div>
            <div className={styles.content}>{reference?.payment_link_type}</div>
          </div>
          <div className={styles.modalDetails}>
            <div className={styles.link}>Limit</div>
            <div className={styles.content}>{reference?.limit}</div>
          </div>
          <div className={styles.modalDetails}>
            <div className={styles.link}>Amount</div>
            <div className={styles.content}>
              {`NGN ${numberWithCommas(reference?.amount)}`}
            </div>
          </div>
          <div className={styles.modalDetails}>
            <div className={styles.link}>Status</div>
            <div className={styles.content}>
              {reference?.is_active ? (
                <div className={styles.active}>Active</div>
              ) : (
                <div className={styles.inactive}>Inactive</div>
              )}
            </div>
          </div>
          <div className={styles.modalDetails}>
            <div className={styles.link}>Description</div>
            <div className={styles.content}>{reference?.description}</div>
          </div>
        </div>
      )}
    </div>
  );
};

export default PaymentModal;
