import React, { useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  useHistory,
} from "react-router-dom";
import SideBar from "../components/sideBar/SideBar";
import NavBar from "../components/navBar/NavBar";
import Dashboard from "../views/Dashboard/Dashboard";
import Transactions from "../views/Transactions/Transactions";
import Compliance from "../views/Compliance/Compliance";
import PaymentLinks from "../views/PaymentLinks/PaymentLinks";
import Customers from "../views/Customers/Customers";
import Disputes from "../views/Disputes/Disputes";
import Products from "../views/Products/Products";
import Refunds from "../views/Refunds/Refunds";
import Balances from "../views/Balances/Balances";
import VirtualWallets from "../views/VirtualWallets/VirtualWallets";
import Settlements from "../views/Settlements/Settlements";
import Payouts from "../views/Payouts/Payouts";
import AuditTrails from "../views/AuditTrails/AuditTrails";
import User from "../views/User/User";
import Business from "../views/Business/Business";
import ComplianceInformation from "../views/ComplianceInfo/ComplianceInfo";
import PayoutAccounts from "../views/PayoutAccounts/PayoutAccounts";
import Roles from "../views/Roles/Roles";
import Preferences from "../views/Preferences/Preferences";
import Webhooks from "../views/Webhooks/Webhooks";
import Subsidiary from "../views/Subsidiary/Subsidiary";
import CustomerDetails from "../views/CustomerDetails/CustomerDetails";
import TransactionDetails from "../views/TransactionDetails/TransactionDetails";
import BalanceHistory from "../views/BalanceHistory/BalanceHistory";
import Invoice from "../views/Invoice/Invoice";
import NewInvoice from "../views/Invoice/NewInvoice/NewInvoice";
import NewProduct from "../views/Products/NewProduct/NewProduct";
import { useSelector } from "react-redux";
// import ScrollToTop from "../ScrollToTop";

export default function DashboardRoutes() {
  const { user } = useSelector((state) => state?.subsidiaryInfoReducer?.subsidiaryInfo?.data)
  const { email_address,
    first_name,
    last_name,
    mobile_number,
    role,
    role_id } = user

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history, history.location.pathname]);

  const addScript = () => {
    (window as any).Intercom('boot', {
      app_id: "kgt0apx9",
      email_address,
      first_name,
      last_name,
      mobile_number,
      role,
      role_id
    });
  }

  addScript()

  return (
    <Router>
      <div style={{ display: "flex", flexDirection: "row", width: "100%" }}>
        <SideBar />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            paddingLeft: "16px",
          }}
        >
          <NavBar />
          <div style={{ marginTop: "88px" }}>
            <Switch>
              <Route exact path="/dashboard/">
                <Dashboard />
              </Route>
              <Route
                exact
                path="/dashboard/compliance"
                component={Compliance}
              ></Route>
              <Route
                exact
                path="/dashboard/business/transactions"
                component={Transactions}
              ></Route>
              <Route
                exact
                path="/dashboard/business/invoice"
                component={Invoice}
              ></Route>
              <Route
                exact
                path="/dashboard/business/invoice/new-invoice"
                component={NewInvoice}
              ></Route>
              <Route
                exact
                path="/dashboard/business/customers"
                component={Customers}
              ></Route>
              <Route
                exact
                path="/dashboard/business/products"
                component={Products}
              ></Route>
              <Route
                exact
                path="/dashboard/business/products/new-product"
                component={NewProduct}
              ></Route>
              <Route
                exact
                path="/dashboard/business/payment-links"
                component={PaymentLinks}
              ></Route>
              <Route
                exact
                path="/dashboard/business/refunds"
                component={Refunds}
              ></Route>
              <Route
                exact
                path="/dashboard/business/disputes"
                component={Disputes}
              ></Route>
              <Route
                exact
                path="/dashboard/account/balances"
                component={Balances}
              ></Route>
              <Route
                exact
                path="/dashboard/account/balances/balance-history/:id"
                component={BalanceHistory}
              ></Route>
              <Route
                exact
                path="/dashboard/account/virtual-wallets"
                component={VirtualWallets}
              ></Route>
              <Route
                exact
                path="/dashboard/account/settlements"
                component={Settlements}
              ></Route>
              <Route
                exact
                path="/dashboard/account/payouts"
                component={Payouts}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/business"
                component={Business}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/compliance-information"
                component={ComplianceInformation}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/payout-accounts"
                component={PayoutAccounts}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/roles-permissions"
                component={Roles}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/subsidiaries"
                component={Subsidiary}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/preference"
                component={Preferences}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/audit-trails"
                component={AuditTrails}
              ></Route>
              <Route
                exact
                path="/dashboard/business/customers/customer-details/:id"
                component={CustomerDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/business/transactions/transaction-details/:id"
                component={TransactionDetails}
              ></Route>
              <Route
                exact
                path="/dashboard/settings/api-webhooks"
                component={Webhooks}
              ></Route>
            </Switch>
          </div>
        </div>
      </div>
    </Router>
  );
}