import React from "react";

export interface CustomersData {
  customer_name: string;
  email_address: string;
  mobile_number: string;
  country_name: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnCustomers = [
  {
    Header: "Name",
    accessor: "customer_name",
  },
  {
    Header: "Email",
    accessor: "email_address",
  },
  {
    Header: "Mobile Number",
    accessor: "mobile_number",
  },
  {
    Header: "Country",
    accessor: "country_name",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
