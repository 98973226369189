import React from "react";

export interface InvoiceData {
  title: string;
  reference: string;
  amount: string;
  due_date: string;
  status: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnInvoice = [
  {
    Header: "Title",
    accessor: "title",
  },
  {
    Header: "Reference",
    accessor: "reference",
  },
  {
    Header: "Amount",
    accessor: "amount",
  },

  {
    Header: "Due date",
    accessor: "due_date",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
