import React, { Fragment, useState } from "react";
import styles from "./Newinvoice.module.scss";
import OutlineTextInput from "../../../components/input/OutlineTextInput";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";

import StepTwo from "./StepTwo/StepTwo";

const NewInvoice = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  interface initTypes {
    first_name: string;
    last_name: string;
    email_address: string;
    mobile_number?: string;
  }

  const initProps: initTypes = {
    first_name: "",
    last_name: "",
    email_address: "",
    mobile_number: ""
  };

  const [inputs, setInputs] = useState(initProps);
  const [step, setSteps] = useState("one");

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const disableFunction = () => {
    if (
      inputs.first_name === "" ||
      inputs.last_name === "" ||
      inputs.email_address === "" ||
      inputs.mobile_number === ""
    ) {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div className={styles.business}>
      {step === "one" && (
        <Fragment>
          <div className={styles.topSections}>
            <div className={styles.newInvoice}>New Invoice</div>
            <div className={styles.stepButton}>Step 1 of 2</div>
          </div>

          <div className={styles.businessCard} style={{ marginTop: "30px" }}>
            <div className={styles.logoText}>Customer details</div>

            <div className={styles.businessForm}>
              <form className={styles.form}>
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="first_name"
                  inputLabel="Customer first name"
                  inputValue={inputs.first_name}
                />
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="last_name"
                  inputLabel="Customer last name"
                  inputValue={inputs.last_name}
                />
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="email_address"
                  inputLabel="Customer email"
                  inputValue={inputs.email_address}
                />
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="mobile_number"
                  inputLabel="Mobile number"
                  inputValue={inputs.mobile_number}
                />
              </form>
            </div>
          </div>
          <div className={styles.footButtons}>
            <div
              className={styles.backButton}
              onClick={() => {
                history.goBack();
              }}
            >
              Back
            </div>
            {disableFunction() ? (
              <div className={styles.nextButtonDisabled}>Next</div>
            ) : (
              <div
                className={styles.nextButton}
                onClick={() => {
                  setSteps("two");
                }}
              >
                Next
              </div>
            )}
          </div>
        </Fragment>
      )}
      {step === "two" && <StepTwo setSteps={setSteps} stepOneInputs={inputs} />}
    </div>
  );
};

export default NewInvoice;
