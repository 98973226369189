import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import styles from "./profile.module.scss";
import useApi from "../../../hooks/useApi";
import OutlineTextInput from "../../../components/input/OutlineTextInput";
import ImagePlaceholder from "../../../assets/images/Icons/image-placeholder.png";
import { ReactComponent as SaveIcon } from "../../../assets/images/Icons/save.svg";
import industriesApi from "../../../api/industries";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import {
  openLoader,
  closeLoader
} from "../../../redux/actions/loader/loaderActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../../constants/toastStyles";
import { useDispatch, useSelector } from "react-redux";
import client from "../../../api/client";
import Select from "react-select";
import countriesApi from "../../../api/countries";
import complianceApi from "../../../api/compliance";
import StatusView from '../../../components/StatusView/StatusView';

type SelectionProp = {
  selection?: string;
  setSelection: Dispatch<SetStateAction<string>>;
};

const Profile = ({ selection, setSelection }: SelectionProp) => {
  const dispatch = useDispatch();

  const [bvnStatus, setBvnStatus] = useState<boolean>();
  const [businessType, setBusinessType] = useState<any>();

  interface initTypes {
    business_name: string;
    email: string;
    description: string;
    bvn?: string;
    Individual?: string;
    // business_type?: string;
    industry?: string;
    legal_business_name?: string;
    country?: string;
    facebook?: string;
    instagram?: string;
    twitter?: string;
    website?: string;
    city?: string;
    address?: string;
    state?: string;
  }

  const initProps: initTypes = {
    business_name: "",
    email: "",
    description: "",
    bvn: "",
    industry: "",
    // business_type: "",
    legal_business_name: "",
    country: "",
    facebook: "",
    instagram: "",
    twitter: "",
    website: "",
    city: "",
    address: "",
    state: ""
  };

  const [inputs, setInputs] = useState(initProps);

  const fetchProfile = async () => {
    dispatch(openLoader());
    try {
      const response: any = await client.get(
        `/subsidiary/dashboard/kyc/profile/view`
      );

      const profile = response?.data?.data;
      setBvnStatus(profile?.bvn_verified);
      setInputs({
        business_name: profile?.business_name,
        email: profile?.email,
        description: profile?.description,
        bvn: profile?.bvn,
        industry: profile?.industry,
        // business_type: profile?.business_type,
        legal_business_name: profile?.legal_business_name,
        country: profile?.country,
        facebook: profile?.facebook,
        instagram: profile?.instagram,
        twitter: profile?.twitter,
        website: profile?.website,
        city: profile?.city,
        address: profile?.address,
        state: profile?.state
      });
      setBusinessType(profile?.business_type);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;

      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  const getIndustriesApi = useApi(industriesApi.getIndustries);
  const getCountriesApi = useApi(countriesApi.getCountries);

  const fetchIndustries = async () => {
    getIndustriesApi.request();
  };
  const fetchCountries = async () => {
    await getCountriesApi.request();
  };
  const [selectedCountry, setSelectedCountry] = useState<any>();

  let selectedCountryOptions = getCountriesApi?.data?.data?.map(function (
    selectedCountry: any
  ) {
    return {
      value: selectedCountry.short_name,
      label: selectedCountry.name,
      currency: selectedCountry.country_code
    };
  });

  const defaultCountry = selectedCountryOptions?.filter(
    (item: any) => item?.label === inputs?.country
  );

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;

    if (inputName === "bvn") {
      /^[0-9]*$/.test(newValue) &&
        setInputs((prevState: initTypes) => {
          return {
            ...prevState,
            [inputName]: newValue
          };
        });
    } else {
      setInputs((prevState: initTypes) => {
        return {
          ...prevState,
          [inputName]: newValue
        };
      });
    }
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post("/subsidiary/dashboard/business/update", {
        ...inputs,
        industry_sector: inputs?.industry,
        country: !!selectedCountry
          ? selectedCountry?.value
          : defaultCountry.pop().value
      })
      .then((data: any) => {
        dispatch(closeLoader());
        setSelection("Bank Details");
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles
          })
        );
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles
          })
        );
      });
  };

  const verifyBvn = async (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());

    try {
      const { data }: any = await client.get(
        `subsidiary/dashboard/kyc/bvn/consent/${inputs?.bvn}`
      );
      dispatch(closeLoader());
      window.open(data?.data?.redirect_url, "_blank");
    } catch (err: any) {
      dispatch(closeLoader());
      const message = err?.response?.data?.message;

      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };
  useEffect(() => {
    fetchIndustries();
    fetchCountries();
    fetchProfile();
  }, []);

  if (getIndustriesApi.loading) {
    return <div className={styles.loading} />;
  }

  const data = getIndustriesApi?.data?.data;

  const industries = data?.map((item: any) => item.name);

  return (
    <div className={styles.business}>
      <div className={styles.businessCard} style={{ marginTop: "30px" }}>
        <div className={styles.logoText}>Profile</div>
        <div className={styles.businessForm}>
          <form className={styles.form}>
            {/* <OutlineTextInput
              inputName="business_type"
              inputLabel="Business type"
              inputSelect
              inputOption={["Individual", "Company"]}
              handleChange={updateProps}
              inputValue={inputs?.business_type}
            /> */}
            <OutlineTextInput
              handleChange={updateProps}
              inputName="email"
              inputLabel="Business email"
              inputValue={inputs?.email}
            />
            <div>
              <label className={styles.inputLabel}>Country *</label>
              <Select
                defaultValue={{
                  value: "NG",
                  label: "Nigeria",
                  currency: "+234"
                }}
                onChange={setSelectedCountry}
                options={selectedCountryOptions}
                // styles={customStyles}
                className={styles.select}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 })
                }}
              />
            </div>

            <OutlineTextInput
              handleChange={updateProps}
              inputName="state"
              inputLabel="State"
              inputValue={inputs?.state}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="address"
              inputLabel="Address"
              inputValue={inputs?.address}
            />

            <OutlineTextInput
              handleChange={updateProps}
              inputName="city"
              inputLabel="City"
              inputValue={inputs?.city}
            />
            {businessType === "Individual" && (
              <>
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="website"
                  inputLabel="Website"
                  inputValue={inputs?.website}
                />
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="facebook"
                  inputLabel="Facebook"
                  inputValue={inputs?.facebook}
                />
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="twitter"
                  inputLabel="Twitter"
                  inputValue={inputs?.twitter}
                />
                <OutlineTextInput
                  handleChange={updateProps}
                  inputName="instagram"
                  inputLabel="Instagram"
                  inputValue={inputs?.instagram}
                />
              </>
            )}

            <OutlineTextInput
              handleChange={updateProps}
              inputName="description"
              inputLabel="Description"
              multiline
              inputValue={inputs?.description}
            />
            <div className={styles.logoText}>Registration</div>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="business_name"
              inputLabel="Business name"
              inputValue={inputs?.business_name}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="legal_business_name"
              inputLabel="Legal business name"
              inputValue={inputs?.legal_business_name}
            />
            {bvnStatus === true && (
              <OutlineTextInput
                handleChange={updateProps}
                inputName="bvn"
                inputLabel="BVN"
                inputValue={inputs?.bvn}
                disabled={true}
              // inputType="number"
              />
            )}
            <OutlineTextInput
              inputName="industry"
              inputLabel="Industry"
              inputValue={inputs?.industry}
              inputSelect
              inputOption={industries}
              handleChange={updateProps}
            />
          </form>
          <div
            className={styles.uploadButton}
            onClick={(e) => {
              handleSubmit(e);
            }}>
            <SaveIcon /> <span>Save and Continue</span>
          </div>
        </div>
      </div>

      {bvnStatus !== true && (
        <div className={styles.businessCard} style={{ marginTop: "30px" }}>
          <div className={styles.logoText}>BVN Verification {" "} <StatusView status={bvnStatus ? "Verified" : "Pending-verification"} orange={"Pending-verification"} style={{ marginLeft: "4px" }} /></div>
          <div className={styles.businessForm}>
            <form className={styles.form}>
              <OutlineTextInput
                handleChange={updateProps}
                inputName="bvn"
                inputLabel="BVN"
                inputValue={inputs?.bvn}
              // inputType="number"
              />
            </form>
            <div
              className={
                inputs?.bvn && inputs?.bvn?.length >= 10
                  ? styles.uploadButton
                  : styles.uploadButtonDisabled
              }
              onClick={
                inputs?.bvn && inputs?.bvn?.length >= 10
                  ? (e) => {
                    verifyBvn(e);
                  }
                  : () => { }
              }>
              <SaveIcon /> <span>Verify</span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Profile;
