import client from "./client";

const getSettlementAccount = async () => {
  const { data } = await client.get(
    "/subsidiary/dashboard/settlement/details/get"
  );

  return data;
};

export default getSettlementAccount;
