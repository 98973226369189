import React, { useState, useEffect } from "react";
import BankDetails from "./BankDetails/BankDetails";
import styles from "./compliance.module.scss";
import Information from "./Information/Information";
import Profile from "./Profile/Profile";
import Kyc from "./Kyc/Kyc";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { ToastErrorStyles } from "../../constants/toastStyles";

const ComplianceInfo = () => {
  const dispatch = useDispatch();
  const getCompliance = !!sessionStorage.getItem("compliance")
    ? sessionStorage.getItem("compliance")
    : "Profile";

  const [selection, setSelection] = useState<any>(getCompliance);
  const [businessType, setBusinessType] = useState<any>();

  const fetchProfile = async () => {
    dispatch(openLoader());
    try {
      const response: any = await client.get(
        `/subsidiary/dashboard/kyc/profile/view`
      );
      const profile = response?.data?.data;
      setBusinessType(profile?.business_type);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;

      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  useEffect(() => {
    fetchProfile();
  });
  return (
    <div>
      <div>
        <div
          className={
            selection === "Profile" ? styles.selectionActive : styles.selection
          }
          onClick={() => {
            setSelection("Profile");
            sessionStorage.setItem("compliance", "Profile");
          }}
        >
          Profile
        </div>
        <div
          className={
            selection === "Bank Details"
              ? styles.selectionActive
              : styles.selection
          }
          onClick={() => {
            setSelection("Bank Details");
            sessionStorage.setItem("compliance", "Bank Details");
          }}
        >
          Bank Details
        </div>
        {businessType?.toLowerCase() === "company" && (
          <div
            className={
              selection === "Information"
                ? styles.selectionActive
                : styles.selection
            }
            onClick={() => {
              setSelection("Information");
              sessionStorage.setItem("compliance", "Information");
            }}
          >
            Director’s Information
          </div>
        )}
        <div
          className={
            selection === "Kyc" ? styles.selectionActive : styles.selection
          }
          onClick={() => {
            setSelection("Kyc");
            sessionStorage.setItem("compliance", "Kyc");
          }}
        >
          KYC Details
        </div>
      </div>
      {selection === "Profile" && (
        <Profile selection={selection} setSelection={setSelection} />
      )}
      {selection === "Bank Details" && (
        <BankDetails
          selection={selection}
          setSelection={setSelection}
          businessType={businessType}
        />
      )}
      {selection === "Information" && (
        <Information selection={selection} setSelection={setSelection} />
      )}
      {selection === "Kyc" && (
        <Kyc selection={selection} setSelection={setSelection} />
      )}
    </div>
  );
};

export default ComplianceInfo;
