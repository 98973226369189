import axios from "axios";

const client = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "content-type": "application/json",
     CORS_ALLOW_ALL_ORIGINS: true,
     "Access-Control-Allow-Origin": "*",
     "Access-Control-Allow-Headers": " Content-Type",
     "Access-Control-Allow-Methods": "POST, GET, OPTIONS, PUT, DELETE"
  }
});

client.interceptors.request.use(
  async (config) => {
    const access_token = sessionStorage.getItem("access_token");
    config.headers.authorization = `Bearer ${access_token}`;
    return config;
  },
  (error) => {
    if (error?.response?.data?.status_code === 401) {
      window.location.href = "/";
    } else return Promise.reject(error);
  }
);

export const formClient = axios.create({
  baseURL: `${process.env.REACT_APP_BASE_URL}`,
  headers: {
    "content-type": "multipart/form-data"
  }
});

formClient.interceptors.request.use(
  async (config) => {
    const access_token = sessionStorage.getItem("access_token");
    config.headers.authorization = `Bearer ${access_token}`;
    return config;
  },
  (error) => {
    if (error?.response?.data?.message === "Please login again") {
      window.location.href = "/";
    } else return Promise.reject(error);
  }
);

export default client;
