import React, { useState } from "react";
import styles from "./Topup.module.scss";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { ReactComponent as ContentCopy } from "../../../assets/images/Icons/content-copy.svg";

const TopUpModal = ({ closeModal, accountNumber, bankName }: any) => {
  const [copied, setCopied] = useState(false);
  if (copied) {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }
  return (
    <div>
      <div className={styles.modalBody}>
        <div className={styles.modalHeader}>
          <div className={styles.background}>
            <div className={styles.linkDetails}>Top up</div>
            <CloseIcon onClick={closeModal} />
          </div>
        </div>
        <div className={styles.modalContent}>
          <div className={styles.confirmation}>
            To fund your wallet, transfer the amount you want to fund to the
            account details below and your balance will be funded.
          </div>
          <div className={styles.accountNumber}>
            {bankName} - {accountNumber}
          </div>
          <CopyToClipboard text={accountNumber} onCopy={() => setCopied(true)}>
            <div className={styles.copy}>
              {copied ? (
                <div>Copied to clipboard!</div>
              ) : (
                <div>
                  <ContentCopy />
                  Copy
                </div>
              )}
            </div>
          </CopyToClipboard>
        </div>
      </div>
    </div>
  );
};

export default TopUpModal;
