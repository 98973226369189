import React, { useState, Fragment } from "react";
import styles from "./Payoutmenu.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import RefundIcon from "../../../assets/images/Icons/refund-icon.svg";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import moment from "moment";
import { numberWithCommas } from "../../../utils/formatNumber";

type MenuProps = {
  data: any;
};
const PayoutMenu = ({ data }: MenuProps) => {
  const dispatch = useDispatch();
  const [menuOption, setMenuOption] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <Menus
                icon={ViewIcon}
                optionName={"View details"}
                onclick={() => setIsOpen(true)}
              />
            </div>
          )}
        </div>
      </ClickAwayListener>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <div className={styles.background}>
              <div className={styles.linkDetails}>Payout Details</div>
              <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
            </div>
          </div>
          {data && (
            <div className={styles.modalContent}>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Date Initiated</div>
                <div className={styles.content}>
                  {moment(data?.date_initiated).format("MMMM Do YYYY, h:mm a")}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Status</div>
                <div className={styles.content}>
                  {data?.status === "Rejected" ? (
                    <div className={styles.inactive}>Rejected</div>
                  ) : data?.status === "Failed" ? (
                    <div className={styles.inactive}>Failed</div>
                  ) : data?.status === "Declined" ? (
                    <div className={styles.inactive}>Declined</div>
                  ) : data?.status === "Pending" ? (
                    <div className={styles.processing}>Pending</div>
                  ) : data?.status === "Processing" ? (
                    <div className={styles.processing}>Processing</div>
                  ) : data?.status === "Successful" ? (
                    <div className={styles.active}>Successful</div>
                  ) : data?.status === "Approved" ? (
                    <div className={styles.active}>Approved</div>
                  ) : null}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Bank Name</div>
                <div className={styles.content}>{data?.bank_name}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Payout fee</div>
                <div className={styles.content}>
                  {`${data?.debit_currency} ${numberWithCommas(data?.fee)}`}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Date Settled</div>
                <div className={styles.content}>
                  {moment(data?.date_settled).format("MMMM Do YYYY, h:mm a")}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}> Amount</div>
                <div className={styles.content}>{`${
                  data?.debit_currency
                } ${numberWithCommas(data?.amount)}`}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Type</div>
                <div className={styles.content}> {data?.type}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Initiator</div>
                <div className={styles.content}> {data?.initiator}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Approver</div>
                <div className={styles.content}>
                  {" "}
                  {data?.approver || "N/A"}{" "}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Account Number</div>
                <div className={styles.content}> {data?.account_number}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Debit Currency</div>
                <div className={styles.content}> {data?.debit_currency}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Recipient Name</div>
                <div className={styles.content}> {data?.recipient_name}</div>
              </div>

              <div className={styles.modalDetails}>
                <div className={styles.link}>Batch Reference#</div>
                <div className={styles.content}>{data?.batch_reference}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Payout Reference#</div>
                <div className={styles.content}>{data?.payout_reference}</div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </Fragment>
  );
};

export default PayoutMenu;
