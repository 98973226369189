import React, { useState, useEffect } from "react";
import styles from "./Dashboard.module.scss";
import { useHistory } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import Card from "../../components/Card/Card";
import Chart from "../../components/Chart/Chart";
import BalanceCard from "../../components/Card/BalanceCard";
import TimerIcon from "../../assets/images/Icons/timer.svg";
import VolumeIcon from "../../assets/images/Icons/volume-icon.svg";
import SettlementIcon from "../../assets/images/Icons/settlement-icon.svg";
import BalanceIcon from "../../assets/images/Icons/balance-card-icon.svg";
import LedgerIcon from "../../assets/images/Icons/ledger-icon.svg";
import useApi from "../../hooks/useApi";
import transactionsSummaryApi from "../../api/transactionsSummary";
import balanceApi from "../../api/walletBalance";
import inflowApi from "../../api/inflow";
import client from "../../api/client";

const Dashboard = () => {
  const [filterParam, setFilterParam] = useState<any>("year");
  const [inflowData, setInflowData] = useState<any>();

  const history = useHistory();
  const getTransactionsSummaryApi = useApi(
    transactionsSummaryApi.getTransactionsSummary
  );
  const WalletBalanceApi = useApi(balanceApi.getWalletBalance);

  const fetchInflow = async () => {
    const { data }: any = await client.get(
      `/subsidiary/metric/inflow/outflow?type=${filterParam}`
    );
    setInflowData(data?.data);
  };

  const fetchWalletBalance = async () => {
    WalletBalanceApi.request();
  };
  useEffect(() => {
    fetchWalletBalance();
    fetchInflow();
  }, [filterParam]);

  const [transactionSummary, setTransactionSummary] = useState();

  useEffect(() => {
    getTransactionsSummaryApi.request();
  }, []);

  const walletBalance = WalletBalanceApi?.data?.wallets[0];

  const data = getTransactionsSummaryApi?.data?.data;

  const response = {
    count: data?.count,
    volume: data?.volume,
    settlements: data?.settlements,
    settlement_change: data?.settlement_change,
    volume_change: data?.volume_change,
    count_change: data?.count_change,
    availableBalance: walletBalance?.available_balance,
    balanceChange: walletBalance?.available_balance_change,
    ledgerBalance: walletBalance?.ledger_balance,
    bankName: walletBalance?.account_details?.bank_name,
    accountNumber: walletBalance?.account_details?.account_number,
  };

  return (
    <div>
      {getTransactionsSummaryApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <>
          <Grid container spacing={2}>
            <Grid item md={4} xs={12}>
              <Card
                icon={TimerIcon}
                percentage={response.count_change}
                amount={response.count}
                transactionType="Transaction count"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Card
                icon={VolumeIcon}
                percentage={response.volume_change}
                amount={response.volume}
                transactionType="Transaction volume"
              />
            </Grid>
            <Grid item md={4} xs={12}>
              <Card
                icon={SettlementIcon}
                percentage={response.settlement_change}
                amount={response.settlements}
                transactionType="Transaction settlements"
              />
            </Grid>
          </Grid>
          <div className={styles.balance}>
            <Grid container spacing={2}>
              <Grid item md={4} xs={12}>
                <BalanceCard
                  icon={BalanceIcon}
                  percentage={walletBalance && response.balanceChange}
                  amount={response.availableBalance}
                  infoContent="Your available balance is money for transfers."
                  transactionType="Available balance"
                  onClick={() => {
                    history.push("dashboard/account/balances");
                  }}
                />
                <div className={styles.balanceCardTwo}>
                  <BalanceCard
                    icon={LedgerIcon}
                    infoContent="This is how much you have made and will be settled in your preferred account on the due date."
                    percentage={response.balanceChange}
                    amount={response.ledgerBalance}
                    transactionType="Ledger balance"
                    onClick={() => {
                      history.push("dashboard/account/balances");
                    }}
                  />
                </div>
              </Grid>
              <Grid item md={8} xs={12}>
                <div className={styles.dashboardChart}>
                  <Chart
                    inflowData={inflowData}
                    filterParam={filterParam}
                    setFilterParam={setFilterParam}
                  />
                </div>
              </Grid>
            </Grid>
          </div>
        </>
      )}
    </div>
  );
};

export default Dashboard;
