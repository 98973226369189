import React, { useState, useEffect } from "react";
import styles from "./Webhooks.module.scss";
import WebhookCard from "../../components/WebhookCard/WebhookCard";
import { ReactComponent as CloseIcon } from "../../assets/images/Icons/close.svg";
import webhooksApi from "../../api/webhooks";
import useApi from "../../hooks/useApi";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import OutlineTextInput from "../../components/input/OutlineTextInput";
import client from "../../api/client";
import { closeLoader, openLoader } from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { ToastSuccessStyles, ToastErrorStyles } from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";

const Webhooks = () => {
  const dispatch = useDispatch();
  const getWebhooksApi = useApi(webhooksApi.getWebhooks);
  const getWebhooksUrlApi = useApi(webhooksApi.getWebhooksUrl);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }



  const webhooks = getWebhooksApi?.data?.api_keys;
  const publicKey = webhooks?.public_key;
  const secretKey = webhooks?.secret_key;
  const encryptionKey = webhooks?.encryption_key;

  const webhooksUrl = getWebhooksUrlApi?.data?.data?.callback_url;

  interface initTypes {
    Default_Webhook?: string;
    AuthField?: string;
    AuthKey?: string;
  }

  const initProps: initTypes = {
    Default_Webhook: "",
    AuthField: "",
    AuthKey: ""
  };

  const [inputs, setInputs] = React.useState(initProps);

  const disableFunction = () => {
    if (inputs.Default_Webhook) {
      return true;
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const handleSubmit = async () => {
    dispatch(openLoader());
    try {
      const data: any = await client.post(
        "subsidiary/dashboard/default/webhook",
        inputs
      );

      const message = data?.data?.message;
      getWebhooksApi.request();
      getWebhooksUrlApi.request();
      dispatch(closeLoader());
      closeModal();
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
    } catch (error: any) {
      dispatch(closeLoader());
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message
        })
      );
    }
  };

  useEffect(() => {
    getWebhooksApi.request();
    getWebhooksUrlApi.request();
  }, []);
  return (
    <div className={styles.webhooks}>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}>
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <div className={styles.background}>
              <div className={styles.linkDetails}>Update webhook URL</div>
              <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
            </div>
          </div>
          <div className={styles.businessForm}>
            <form onSubmit={handleSubmit} className={styles.form}>
              <OutlineTextInput
                handleChange={updateProps}
                inputName="Default_Webhook"
                inputLabel="Webhook URL"
                inputValue={inputs.Default_Webhook}
              />

              <OutlineTextInput
                handleChange={updateProps}
                inputName="AuthField"
                inputLabel="Authentication field"
                inputValue={inputs.AuthField}
                notRequired
              />
              <OutlineTextInput
                handleChange={updateProps}
                inputName="AuthKey"
                inputLabel="Authentication key"
                inputValue={inputs.AuthKey}
                notRequired
              />
            </form>
          </div>
          <div className={styles.modalFooter}>
            <div className={styles.cancel} onClick={closeModal}>
              Cancel
            </div>
            {disableFunction() ? (
              <div className={styles.deactivate} onClick={handleSubmit}>
                Update webhook  URL
              </div>
            ) : (
              <div className={styles.notClickable}>Update webhook  URL</div>
            )}
          </div>
        </div>
      </Modal>
      {getWebhooksApi?.loading ? (
        <div />
      ) : (
        <>
          <WebhookCard
            urlName="Webhook URL"
            urlContent={webhooksUrl}
            canUpdate
            updateNode={<div className={styles.copy} onClick={() => setIsOpen(true)}> Update</div>}
          />
          <WebhookCard urlName="Public key" urlContent={publicKey} />
          <WebhookCard urlName="Secret key" urlContent={secretKey} />
          <WebhookCard urlName="Encryption key" urlContent={encryptionKey} />
        </>
      )}
    </div>
  );
};

export default Webhooks;
