import React, { useState, Fragment } from "react";
import styles from "./Balancemenu.module.scss";
import { ReactComponent as MenuIcon } from "../../../assets/images/Icons/menu.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import RefundIcon from "../../../assets/images/Icons/refund-icon.svg";
import ViewIcon from "../../../assets/images/Icons/view-details.svg";
import Menus from "../Menus/Menus";
import { ReactComponent as CloseIcon } from "../../../assets/images/Icons/close.svg";
import client from "../../../api/client";
import { useDispatch } from "react-redux";
import Modal from "react-modal";
import { customStyles } from "../../../constants/modalStyles";
import moment from "moment";
import { numberWithCommas } from "../../../utils/formatNumber";

type MenuProps = {
  data: any;
};
const BalanceMenu = ({ data }: MenuProps) => {
  const dispatch = useDispatch();
  const [menuOption, setMenuOption] = useState(false);

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  function openModal() {
    setIsOpen(true);
  }
  const handleClickAway = () => {
    setMenuOption(false);
  };
  const handleMenu = () => {
    setMenuOption(!menuOption);
  };

  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.menuBody}>
          <div className={styles.menuIcon} onClick={handleMenu}>
            <MenuIcon />
          </div>

          {menuOption && (
            <div className={styles.menuContent} onClick={handleClickAway}>
              <Menus
                icon={ViewIcon}
                optionName={"View details"}
                onclick={() => setIsOpen(true)}
              />
            </div>
          )}
        </div>
      </ClickAwayListener>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <div className={styles.modalBody}>
          <div className={styles.modalHeader}>
            <div className={styles.background}>
              <div className={styles.linkDetails}>Refund Details</div>
              <CloseIcon onClick={closeModal} style={{ cursor: "pointer" }} />
            </div>
          </div>
          {data && (
            <div className={styles.modalContent}>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Date</div>
                <div className={styles.content}>
                  {moment(data?.date_created).format("MMMM Do YYYY, h:mm a")}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Amount</div>
                <div className={styles.content}>
                  {`NGN ${numberWithCommas(data?.amount)}`}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Direction</div>
                <div className={styles.content}>
                  {data?.direction === "Credit" ? (
                    <div className={styles.active}>Credit</div>
                  ) : (
                    <div className={styles.inactive}>Debit</div>
                  )}
                </div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Balance</div>
                <div className={styles.content}>{`NGN ${numberWithCommas(
                  data?.balance
                )}`}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Details</div>
                <div className={styles.content}> {data?.remarks}</div>
              </div>
              <div className={styles.modalDetails}>
                <div className={styles.link}>Reference#</div>
                <div className={styles.content}>{data?.reference}</div>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </Fragment>
  );
};

export default BalanceMenu;
