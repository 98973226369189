import React, { useState, Fragment, useEffect } from "react";
import styles from "./Audittrails.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { AuditColumn, AuditData } from "../../types/TablesTypes/AuditTrails";
import ExportButton from "../../components/ExportButton/ExportButton";
import StatusView from "../../components/StatusView/StatusView";
import { ReactComponent as DropArrow } from "../../assets/images/Icons/drop-arrow.svg";
import auditTrailsApi from "../../api/auditTrails";
import useApi from "../../hooks/useApi";
import { numberWithCommas } from "../../utils/formatNumber";
import TableHeader from "../../components/TableHeader/TableHeader";
import moment from "moment";

const AuditTrails = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const getAuditTrailsApi = useApi(auditTrailsApi.getAuditTrails);

  useEffect(() => {
    getAuditTrailsApi.request();
  }, []);

  const dataTransaction = () => {
    const tempArr: AuditData[] = [];
    getAuditTrailsApi?.data?.items
      ?.slice(0)
      .reverse()
      .forEach((audit: AuditData, index: number) => {
        tempArr.push({
          name: audit?.name,
          role: audit?.role,
          email_address: audit?.email_address,
          action: audit?.action,
          ip_address: audit?.ip_address || "N / A",
          activity_type: audit?.activity_type,
          date_created: moment(audit?.date_created).format(
            "MMMM Do YYYY, h:mm a"
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [getAuditTrailsApi?.data?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Audit Trails"
        dataLength={getAuditTrailsApi?.data?.items?.length}
        data={getAuditTrailsApi?.data?.items}
        noShowFilter={true}
      />
      {getAuditTrailsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          columns={AuditColumn ? AuditColumn : []}
          emptyPlaceHolder="You currently do not have any audit"
          total={getAuditTrailsApi?.data?.items?.length}
        />
      )}
    </Fragment>
  );
};

export default AuditTrails;
