import React, { useState, Fragment, useEffect } from "react";
import Modal from "react-modal";
import styles from "./Subsidiary.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import TableHeader from "../../components/TableHeader/TableHeader";
import {
  ColumnSubsidiaries,
  SubsidiariesData
} from "../../types/TablesTypes/Subsidiaries";
import ExportButton from "../../components/ExportButton/ExportButton";
import subsidiariesApi from "../../api/subsidiaries";
import useApi from "../../hooks/useApi";
import { ReactComponent as TopUp } from "../../assets/images/Icons/topup.svg";
import { useSelector } from "react-redux";
import { customStyles } from "../../constants/modalStyles";
import AddNewSub from "../../components/ModalContent/AddNewSub/AddNewSub";
const Subsidiary = () => {
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");

  const getSubsidiariesApi = useApi(subsidiariesApi.getSubsidiaries);

  const [modalIsOpen, setIsOpen] = useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  useEffect(() => {
    getSubsidiariesApi.request();
  }, []);

  const dataPaymentLinks = () => {
    const tempArr: SubsidiariesData[] = [];
    getSubsidiariesApi?.data?.subsidiary_details?.subsidiaries
      ?.slice(0)
      .reverse()
      .forEach((subsidiary: any, index: number) => {
        tempArr.push({
          name: subsidiary?.name,
          support_email: subsidiary?.support_email,
          country: subsidiary?.country,
          business_type: subsidiary?.business_type
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [getSubsidiariesApi?.data?.subsidiary_details?.subsidiaries]);
  return (
    <Fragment>
      <TableHeader
        pageName="Subsidiaries"
        noShowFilter={true}
        value={value}
        setValue={setValue}
        dataLength={
          getSubsidiariesApi?.data?.subsidiary_details?.subsidiaries?.length
        }
        data={getSubsidiariesApi?.data?.subsidiary_details?.subsidiaries}
        newButton={
          <div className={styles.createLink} onClick={() => setIsOpen(true)}>
            <TopUp className={styles.link} />
            Create new subsidiary
          </div>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="test Modal"
        style={customStyles}
      >
        <AddNewSub closeModal={closeModal} />
      </Modal>
      {getSubsidiariesApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          value={value}
          data={tableRow ? tableRow : []}
          columns={ColumnSubsidiaries ? ColumnSubsidiaries : []}
          emptyPlaceHolder="You currently do not have any subsidiaries"
          total={
            getSubsidiariesApi?.data?.subsidiary_details?.subsidiaries?.length
          }
        />
      )}
    </Fragment>
  );
};

export default Subsidiary;
