import React, { useState, Fragment, useEffect } from "react";
import styles from "./Settlements.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnSettlements,
  SettlementsData,
} from "../../types/TablesTypes/Settlements";
import ExportButton from "../../components/ExportButton/ExportButton";
import StatusView from "../../components/StatusView/StatusView";
import { ReactComponent as DropArrow } from "../../assets/images/Icons/drop-arrow.svg";
import settlementsApi from "../../api/settlements";
import useApi from "../../hooks/useApi";
import { numberWithCommas } from "../../utils/formatNumber";
import TableHeader from "../../components/TableHeader/TableHeader";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { ToastErrorStyles } from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";
import SettlementMenu from "../../components/MenuOption/SettlementMenu/SettlementMenu";

const Settlements = () => {
  const dispatch = useDispatch();
  const [value, setValue] = useState("");
  const [status, setStatus] = useState<string>("");
  const [settlements, setSettlements] = useState<any>();
  const [tableRow, setTableRow] = useState<any[]>();
  const getSettlementsApi = useApi(settlementsApi.getSettlements);

  const fetchSettlement = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `/subsidiary/dashboard/settlement/paginated?settlementstatus=${status}`
      );
      setSettlements(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };
  useEffect(() => {
    fetchSettlement();
  }, [status]);

  const dataTransaction = () => {
    const tempArr: SettlementsData[] = [];
    settlements?.items
      ?.slice(0)
      .reverse()
      .forEach((settlement: any, index: number) => {
        tempArr.push({
          subsidiary_name: settlement?.subsidiary_name,
          transaction_amount: `NGN ${numberWithCommas(
            settlement?.transaction_amount
          )}`,
          fee: `NGN ${numberWithCommas(settlement?.fee)}`,
          currency: `${(settlement?.currency).toUpperCase()}`,
          settlement_status: (
            <StatusView
              status={settlement?.settlement_status}
              green="Completed"
              orange="Pending"
              
            />
          ),
          action: <SettlementMenu data={settlement} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [settlements?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Settlement"
        dataLength={settlements?.items?.length}
        data={settlements?.items}
        value={value}
        setValue={setValue}
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Status</div>
            <div className={styles.filterBody}>
              <div
                onClick={() => {
                  setStatus("Completed");
                }}
                style={{ cursor: "pointer" }}
              >
                Completed
              </div>
              <div
                onClick={() => setStatus("Successful")}
                style={{ cursor: "pointer" }}
              >
                Successful
              </div>
            </div>
          </div>
        }
      />
      {getSettlementsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          value={value}
          data={tableRow ? tableRow : []}
          columns={ColumnSettlements ? ColumnSettlements : []}
          emptyPlaceHolder="You currently do not have any settlements"
          total={settlements?.items?.length}
        />
      )}
    </Fragment>
  );
};

export default Settlements;
