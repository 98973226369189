import React, { useState, Fragment, useEffect } from "react";
import styles from "./Customers.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnCustomers,
  CustomersData,
} from "../../types/TablesTypes/Customers";
import MenuOption from "../../components/MenuOption/MenuOption";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import ViewIcon from "../../assets/images/Icons/view-details.svg";
import DeactivateIcon from "../../assets/images/Icons/deactivate-user.svg";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import customersApi from "../../api/customers";
import useApi from "../../hooks/useApi";
import client from "../../api/client";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import CreateCustomer from "../../components/ModalContent/CreateCustomer/CreateCustomer";
import { useHistory } from "react-router-dom";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";

const Customers = () => {
  const [status, setStatus] = useState<string>("");
  const [group, setGroup] = useState<string>("");
  const [value, setValue] = useState("");
  const [customers, setCustomers] = useState<any>();
  const [tableRow, setTableRow] = useState<any[]>();
  const getCustomersApi = useApi(customersApi.getCustomers);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }
  const dispatch = useDispatch();
  const history = useHistory();

  const fetchCustomers = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `/subsidiary/dashboard/fetch/customers?status=${status}`
      );
      setCustomers(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  useEffect(() => {
    fetchCustomers();
  }, [status, group]);

  const menuOptions = [
    {
      id: 1,
      name: (status: string) => {
        return status === "Active"
          ? "Blacklist Customer"
          : "Whitelist Customer";
      },
      icon: DeactivateIcon,
      onclick: (
        customerEmail: string,
        e: React.SyntheticEvent,
        status: string
      ) => {
        const payload = {
          customer_email: customerEmail,
          action: status === "Active" ? "blacklist" : "whitelist",
          reason: "test",
        };
        e.preventDefault();
        client
          .post("/subsidiary/customer/blacklist-whitelist", payload)
          .then((data: any) => {
            fetchCustomers();
            const message = data?.data?.message;
            dispatch(
              openToastAndSetContent({
                toastStyles: ToastSuccessStyles,
                toastContent: message,
              })
            );
          })
          .catch((error: any) => {
            if (error.response) {
              const { message } = error?.response?.data;
              dispatch(
                openToastAndSetContent({
                  toastStyles: ToastErrorStyles,
                  toastContent: message,
                })
              );
            } else if (error.request) {
              console.log(error.request);
            } else {
              console.log("Error", error.message);
            }
          });
      },
    },
    {
      id: 2,
      name: (id: number) => {
        return id ? "View details" : null;
      },
      icon: ViewIcon,
      onclick: (
        customerEmail: string,
        e: React.SyntheticEvent,
        status: string,
        customerId: number
      ) => {
        history.push(
          `/dashboard/business/customers/customer-details/${customerId}`
        );
      },
    },
  ];

  const dataCustomers = () => {
    const tempArr: CustomersData[] = [];
    customers?.items
      ?.slice(0)
      .reverse()
      .forEach((customer: any, index: number) => {
        return tempArr.push({
          customer_name: customer?.customer_name,
          email_address: customer?.email_address,
          mobile_number: customer?.mobile_number,
          country_name: customer?.country_name,
          status: (
            <StatusView
              status={customer?.status}
              green="Active"
              red="Blacklisted"
            />
          ),
          action: (
            <MenuOption
              menuContent={
                <div>
                  {menuOptions.map((option: any) => (
                    <div
                      key={option?.id}
                      onClick={(e) => {
                        option?.onclick(
                          customer?.email_address,
                          e,
                          customer?.status,
                          customer?.id
                        );
                      }}
                      className={styles.menuContent}
                    >
                      <img
                        src={option?.icon}
                        alt={option?.name(customer?.id)}
                        width={24}
                        height={24}
                      />
                      <div className={styles.optionName}>
                        {option?.name(customer?.status)}
                      </div>
                    </div>
                  ))}
                </div>
              }
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataCustomers());
  }, [customers?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Customers"
        data={customers?.items}
        dataLength={customers?.items.length}
        value={value}
        setValue={setValue}
        newButton={
          <div className={styles.createLink} onClick={() => setIsOpen(true)}>
            <LinkIcon className={styles.link} />
            Create new customer
          </div>
        }
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Status</div>
            <div className={styles.filterBody}>
              <div
                onClick={() => {
                  setStatus("Active");
                }}
                style={{ cursor: "pointer" }}
              >
                Active
              </div>
              <div
                onClick={() => setStatus("Blacklisted")}
                style={{ cursor: "pointer" }}
              >
                Blacklisted
              </div>
            </div>

            <div className={styles.filterSubHeading}>Group</div>
            <div className={styles.filterBody}>
              <div
                onClick={() => {
                  setGroup("Default");
                }}
                style={{ cursor: "pointer" }}
              >
                Default
              </div>
            </div>
          </div>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreateCustomer
          fetchCustomers={fetchCustomers}
          closeModal={closeModal}
        />
      </Modal>
      {getCustomersApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          data={tableRow ? tableRow : []}
          value={value}
          columns={ColumnCustomers ? ColumnCustomers : []}
          emptyPlaceHolder="You currently do not have any customers"
          total={customers?.items.length}
        />
      )}
    </Fragment>
  );
};

export default Customers;
