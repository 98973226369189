import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Switch, useLocation } from "react-router-dom";
import ParentContainer from "../components/ParentContainer/ParentContainer";
import SignIn from "../views/SignIn/SignIn";
import SignUp from "../views/SignUp/SignUp";
import DashboardRoutes from "./DashboardRoutes";
import PrivateRoute from "./PrivateRoutes";
import PublicRoute from "./PublicRoutes";
import VerifyEmail from "../views/VerifyEmail/VerifyEmail";
import EmailVerified from "../views/EmailVerified/EmailVerified";
import ForgotPassword from "../views/ForgotPassword/ForgotPassword";
import TwoFaSignin from "../views/SignIn/TwoFaSignIn";




const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

export default function AppRoutes() {
  return (
    <Router>
      <ScrollToTop />
      <ParentContainer>
        <Switch>
          <PublicRoute exact path="/">
            <SignIn />
          </PublicRoute>
          <PublicRoute exact path="/2fa-signin">
            <TwoFaSignin />
          </PublicRoute>
          <PublicRoute exact path="/sign-up">
            <SignUp />
          </PublicRoute>
          <PublicRoute exact path="/forgot-password/:email">
            <ForgotPassword />
          </PublicRoute>
          <PublicRoute exact path="/verify">
            <VerifyEmail />
          </PublicRoute>
          <PublicRoute exact path="/email-verified/:token?">
            <EmailVerified />
          </PublicRoute>
          <PrivateRoute path="/dashboard">
            <DashboardRoutes />
          </PrivateRoute>
        </Switch>
      </ParentContainer>
    </Router>
  );
}
