import React, { useState, Fragment } from "react";
import styles from "./Tablefilter.module.scss";
import { ReactComponent as DropArrow } from "../../assets/images/Icons/drop-arrow.svg";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const TableFilter = ({ FilterComponent }: any) => {
  const [dropdown, setDropdown] = useState(false);

  const handleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleClickAway = () => {
    setDropdown(false);
  };
  return (
    <Fragment>
      <ClickAwayListener onClickAway={handleClickAway}>
        <div className={styles.exportMenu} onClick={handleDropdown}>
          <div className={styles.filter}>
            Filter <DropArrow className={styles.dropArrow} />
          </div>

          {dropdown && (
            <div className={styles.exportDropdown}>{FilterComponent}</div>
          )}
        </div>
      </ClickAwayListener>
    </Fragment>
  );
};

export default TableFilter;
