import React from "react";

export interface SettlementsData {
  subsidiary_name: string;
  transaction_amount: string;
  currency: string;
  fee: string;
  settlement_status: React.ReactNode;
  action: React.ReactNode;
}

export const ColumnSettlements = [
  {
    Header: "Subsidiary Name",
    accessor: "subsidiary_name",
  },
  {
    Header: "Amount",
    accessor: "transaction_amount",
  },
  {
    Header: "Currency",
    accessor: "currency",
  },
  {
    Header: "Settlement Fee",
    accessor: "fee",
  },
  {
    Header: "Status",
    accessor: "settlement_status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
