import React from "react";
import styles from "./balancecard.module.scss";
import TimerIcon from "../../assets/images/Icons/timer.svg";
import { ReactComponent as ArrowIncrease } from "../../assets/images/Icons/trending-increase.svg";
import { ReactComponent as ArrowDecrease } from "../../assets/images/Icons/trending-decrease.svg";
import { ReactComponent as ForwardArrow } from "../../assets/images/Icons/arrow-forward.svg";
import { ReactComponent as InfoIcon } from "../../assets/images/Icons/info.svg";
import { numberWithCommas } from "../../utils/formatNumber";
import IconButton from "@mui/material/IconButton";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

type CardProps = {
  transactionType: string;
  percentage: number;
  amount: number;
  icon: string;
  infoContent: string;
  onClick?: () => void;
};

const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#016E20",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#016E20",
    fontFamily: "'Kumbh Sans'",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: "14px",
    lineHeight: "20px",
    maxWidth: 260,
    padding: "8px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    letterSpacing: "-0.006em",
    color: "#FFFFFF",
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.12)",
    borderRadius: "4px",
  },
}));
const BalanceCard = ({
  transactionType,
  percentage,
  amount,
  icon,
  infoContent,
  onClick,
}: CardProps) => {
  return (
    <div className={styles.balanceCard}>
      <div className={styles.card}>
        <div>
          <img src={icon} alt="timer" height={48} width={48} />
        </div>
        <div className={styles.cardContent}>
          <div className={styles.cardCount}>
            {transactionType}
            <LightTooltip title={infoContent} placement="top" arrow>
              <IconButton>
                <InfoIcon className={styles.infoIcon} />
              </IconButton>
            </LightTooltip>
          </div>
          <div className={styles.cardAmountNgn}>
            <span>NGN</span>
            {numberWithCommas(amount)}
            {/* <div className={styles.doubleZero}>00</div> */}
          </div>

          <div className={styles.cardPercent}>
            {percentage > 0 ? (
              <div className={styles.percentIncrease}>
                <ArrowIncrease />
                <span>+{percentage}%</span>
              </div>
            ) : (
              <div className={styles.percentDecrease}>
                <ArrowDecrease />
                <span>{percentage}%</span>
              </div>
            )}

            <div className={styles.timeLine}>from last week</div>
          </div>
        </div>
      </div>
      <div className={styles.view} onClick={onClick}>
        <span className={styles.balance}>View Balance</span>{" "}
        <ForwardArrow className={styles.forward} />
      </div>
    </div>
  );
};

export default BalanceCard;
