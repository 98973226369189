import React from "react";

export interface PayoutsData {
  date_initiated: string;
  recipient_name: string;
  account_number: string;
  amount: string;
  fee: string;
  type: string;
  action: React.ReactNode;
  status: React.ReactNode;
}

export const ColumnPayouts = [
  {
    Header: "Payout date",
    accessor: "date_initiated",
  },
  {
    Header: "Recipient",
    accessor: "recipient_name",
  },
  {
    Header: "Account number",
    accessor: "account_number",
  },
  {
    Header: "Total amount",
    accessor: "amount",
  },
  {
    Header: "Type",
    accessor: "type",
  },
  {
    Header: "Settled amount",
    accessor: "fee",
  },
  {
    Header: "Status",
    accessor: "status",
  },
  {
    Header: "Action",
    accessor: "action",
  },
];
