import React from "react";

export interface BankSettlementData {
  account_name: string;
  account_number: string;
  bank_name: string;
}

export const ColumnBankSettlement = [
  {
    Header: "Account Name",
    accessor: "account_name",
  },
  {
    Header: "Account Number",
    accessor: "account_number",
  },
  {
    Header: "Bank name",
    accessor: "bank_name",
  },
];
