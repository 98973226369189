import React, { useState, ReactNode } from "react";
import styles from "./Webhookcard.module.scss";
import { ReactComponent as ContentCopy } from "../../assets/images/Icons/content-copy.svg";
import { CopyToClipboard } from "react-copy-to-clipboard";

type WebhookCardProps = {
  urlName?: string;
  urlContent: string;
  canUpdate?: boolean;
  updateNode?: ReactNode;
};

const WebhookCard = ({ urlName, urlContent, canUpdate, updateNode }: WebhookCardProps) => {
  const [copied, setCopied] = useState(false);
  if (copied) {
    setTimeout(() => {
      setCopied(false);
    }, 2000);
  }

  return (
    <div className={styles.webhookUrl}>
      <div className={styles.urlName}>{urlName}</div>
      <div className={styles.urlContent}>
        {urlContent && urlContent?.length > 100
          ? urlContent?.substring(0, 40) + "..."
          : urlContent}
      </div>
      <div className={styles.action}>
        <CopyToClipboard text={urlContent} onCopy={() => setCopied(true)}>
          <div className={styles.copy}>
            {copied ? (
              <div>Copied to clipboard!</div>
            ) : (
              <div>
                <ContentCopy />
                Copy
              </div>
            )}
          </div>
        </CopyToClipboard>
        {canUpdate && <div>{updateNode}</div>}
      </div>
    </div>
  );
};

export default WebhookCard;
