import React, { useState, Fragment, useEffect } from "react";
import styles from "./Payoutaccounts.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnBankSettlement,
  BankSettlementData,
} from "../../types/TablesTypes/bankSettlement";
import StatusView from "../../components/StatusView/StatusView";
import TableHeader from "../../components/TableHeader/TableHeader";
import payoutsApi from "../../api/payouts";
import virtualWalletsApi from "../../api/virtualWallets";
import useApi from "../../hooks/useApi";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import { numberWithCommas } from "../../utils/formatNumber";
import moment from "moment";
import Radio from "@mui/material/Radio";
import { useDispatch } from "react-redux";

import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import AddBankAccount from "../../components/ModalContent/AddBankAccount/AddBankAccount";
import getSettlementAccount from "../../api/getSettlementAccount";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../constants/toastStyles";

const PayoutAccounts = () => {
  const dispatch = useDispatch();
  const [tableRow, setTableRow] = useState<any[]>();
  const getPayoutsApi = useApi(virtualWalletsApi.getSubsidiaryBanks);
  const [value, setValue] = useState("");

  const [selectedValue, setSelectedValue] = React.useState();

  const getAccount = async () => {
    const { data }: any = await getSettlementAccount();
    setSelectedValue(data);
  };

  useEffect(() => {
    getPayoutsApi.request();
    getAccount();
  }, []);

  const fetchPayout = async () => {
    await getPayoutsApi.request();
  };

  const dataPayout = () => {
    const tempArr: BankSettlementData[] = [];
    getPayoutsApi?.data?.data
      ?.slice(0)
      .reverse()
      .forEach((virtualWallet: any, index: number) => {
        tempArr.push({
          account_name: virtualWallet?.account_name,
          account_number: virtualWallet?.account_number,
          bank_name: virtualWallet?.bank_name,
        });
      });
    return tempArr;
  };

  interface initTypes {
    Account?: string;
    Bank?: string;
  }

  const initProps: initTypes = {
    Account: "wallet",
    Bank: "bank",
  };

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const [inputs, setInputs] = React.useState(initProps);
  const handleChange = (event: any) => {
    setSelectedValue(event.target.value);
    setPaymentOption(event.target.value);
  };

  const setPaymentOption = async (option: string) => {
    try {
      const data: any = await client.post(
        "/subsidiary/dashboard/settlement/details",
        {
          settlement_type: option,
        }
      );

      const message = data?.data?.message;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message,
        })
      );
    } catch (error: any) {
      const { message } = error?.response?.data;
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastErrorStyles,
          toastContent: message,
        })
      );
    }
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  useEffect(() => {
    setTableRow(dataPayout());
  }, [getPayoutsApi?.data?.data]);
  return (
    <Fragment>
      <div className={styles.accounts}>
        <div className={styles.accountInfo}>Accounts</div>
        <div className={styles.accountEarnings}>
          Which account would you like to get your earnings?
        </div>
        <div className={styles.radioSelection_one}>
          <Radio
            checked={selectedValue === inputs.Bank}
            onChange={handleChange}
            value={inputs.Bank}
            name="bank"
            inputProps={{ "aria-label": inputs.Bank }}
            size="small"
          />
          <div className={styles.radioLabel}>Settle to bank </div>
        </div>
        <div className={styles.radioSelection}>
          <Radio
            checked={selectedValue === inputs.Account}
            onChange={handleChange}
            value={inputs.Account}
            name="wallet"
            inputProps={{ "aria-label": inputs.Account }}
            size="small"
          />
          <div className={styles.radioLabel}>Settle to Payinvert account </div>
        </div>
      </div>
      <TableHeader
        pageName="Bank accounts"
        value={value}
        setValue={setValue}
        noShowFilter={true}
        dataLength={getPayoutsApi?.data?.data?.length}
        data={getPayoutsApi?.data?.items}
        newButton={
          <div className={styles.createLink} onClick={() => setIsOpen(true)}>
            <LinkIcon className={styles.link} />
            Add Bank Account
          </div>
        }
      />

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <AddBankAccount fetchPayouts={fetchPayout} closeModal={closeModal} />
      </Modal>

      {getPayoutsApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          value={value}
          data={tableRow ? tableRow : []}
          columns={ColumnBankSettlement ? ColumnBankSettlement : []}
          emptyPlaceHolder="You currently do not have any payout accounts"
          total={getPayoutsApi?.data?.data?.length}
        />
      )}
    </Fragment>
  );
};

export default PayoutAccounts;
