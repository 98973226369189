import React from "react";
import styles from "./Verifyemail.module.scss";
import Logo from "../../assets/images/Logos/Pay-Invert-Logo.svg";
import { ReactComponent as SignInIcon } from "../../assets/images/Icons/signin.svg";
import { ReactComponent as AccountIcon } from "../../assets/images/Icons/account.svg";
import OutlineTextInput from "../../components/input/OutlineTextInput";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { saveSubsidiaryInfo } from "../../redux/actions/subsidiaryInfo/subsidiaryInfoActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles,
} from "../../constants/toastStyles";

const VerifyEmail = () => {
  const todaysDate = new Date();
  const dispatch = useDispatch();
  const history = useHistory();
  interface initTypes {
    Email: string;
    Password?: string;
  }

  const initProps: initTypes = {
    Email: "",
    Password: "",
  };
  const [inputs, setInputs] = React.useState(initProps);

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue,
      };
    });
  };

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    client
      .post("/subsidiary/dashboard/login", inputs)
      .then((data: any) => {
        dispatch(closeLoader());
        dispatch(saveSubsidiaryInfo(data));
        const { status, message, token } = data.data;
        const { access_token } = token;
        sessionStorage.setItem("access_token", access_token);

        if (status === "success") {
          history.push("/dashboard");
        }
      })
      .catch((error: any) => {
        // <ErrorToast error={error} />;
        if (error.response) {
          dispatch(closeLoader());
          const message = error?.response?.data?.message;

          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles,
            })
          );
        } else if (error.request) {
          dispatch(closeLoader());
        } else {
          dispatch(closeLoader());
        }
      });
  };

  return (
    <div className={styles.signInBody}>
      <div className={styles.logo}>
        <img src={Logo} width={207} height={56} alt="pay-invert" />
      </div>
      <div className={styles.signinForm}>
        <div className={styles.signIn}>
          A message has been sent to your mail, verify to sign in.
        </div>
      </div>
      <div className={styles.newAccount}>
        <div className={styles.noAccount}>Back to sign in page</div>
        <div
          className={styles.create}
          onClick={() => {
            history.push("/");
          }}
        >
          <AccountIcon /> Signin
        </div>
      </div>
      <div className={styles.sidebarFooter}>
        <div>Copyright &#169; Payinvert {todaysDate.getFullYear()}.</div>
        <div>All rights reserved</div>
      </div>
    </div>
  );
};

export default VerifyEmail;
