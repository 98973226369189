import React, { useState, Fragment, useEffect } from "react";
import styles from "./Paymentlinks.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import {
  ColumnPaymentLinks,
  PaymentLinksData,
} from "../../types/TablesTypes/PaymentLinks";
import TableHeader from "../../components/TableHeader/TableHeader";
import MenuOption from "../../components/MenuOption/MenuOption";
import { ReactComponent as LinkIcon } from "../../assets/images/Icons/link-icon.svg";
import CopyIcon from "../../assets/images/Icons/copy.svg";
import paymentLinksApi from "../../api/paymentLinks";
import useApi from "../../hooks/useApi";
import { numberWithCommas } from "../../utils/formatNumber";
import { useDispatch } from "react-redux";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import PaymentLinkMenu from "../../components/MenuOption/PaymentLinkMenu/PaymentLinkMenu";
import CreatePaymentLink from "../../components/ModalContent/CreatePaymentLink/CreatePaymentLink";
import { ToastErrorStyles } from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";

const PaymentLinks = () => {
  const dispatch = useDispatch();
  const [status, setStatus] = useState<string>("");
  const [paymentLinks, setPaymentLinks] = useState<any>();
  const [tableRow, setTableRow] = useState<any[]>();
  const [value, setValue] = useState("");
  const getPaymentLinksApi = useApi(paymentLinksApi.getPaymentLinks);
  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const fetchPaymentLink = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `/subsidiary/dashboard/payment/link/subsidiary?is_active=${status}`
      );
      setPaymentLinks(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.status_code === 401) {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };
  useEffect(() => {
    fetchPaymentLink();
  }, [status]);

  const StatusView = ({ status, green, orange, red }: any) => {
    return (
      <div>
        {status === red ? (
          <div className={styles.declined}>Inactive</div>
        ) : status === orange ? (
          <div className={styles.processing}>{orange}</div>
        ) : status === green ? (
          <div className={styles.completed}>Active</div>
        ) : null}
      </div>
    );
  };

  const dataPaymentLinks = () => {
    const tempArr: PaymentLinksData[] = [];
    paymentLinks?.items
      ?.slice(0)
      .reverse()
      .forEach((paymentLink: any, index: number) => {
        tempArr.push({
          name: paymentLink?.name,
          payment_type: paymentLink?.payment_type,
          limit: paymentLink?.limit,
          amount: `NGN ${numberWithCommas(paymentLink?.amount)}`,
          is_active: (
            <>
              <StatusView
                status={paymentLink?.is_active}
                green={true}
                red={false}
              />
            </>
          ),
          action: (
            <PaymentLinkMenu
              reference={paymentLink?.reference}
              status={paymentLink?.is_active}
              fetchPaymentLink={fetchPaymentLink}
            />
          ),
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataPaymentLinks());
  }, [paymentLinks?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Payment links"
        data={paymentLinks?.items}
        value={value}
        setValue={setValue}
        dataLength={paymentLinks?.items?.length}
        newButton={
          <div className={styles.createLink} onClick={() => setIsOpen(true)}>
            <LinkIcon className={styles.link} />
            Create new link
          </div>
        }
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Status</div>
            <div className={styles.filterBody}>
              <div
                onClick={() => {
                  setStatus("Active");
                }}
                style={{ cursor: "pointer" }}
              >
                Active
              </div>
              <div
                onClick={() => setStatus("Inactive")}
                style={{ cursor: "pointer" }}
              >
                Inactive
              </div>
            </div>
          </div>
        }
      />
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <CreatePaymentLink
          closeModal={closeModal}
          fetchPaymentLink={fetchPaymentLink}
        />
      </Modal>
      {getPaymentLinksApi.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          value={value}
          data={tableRow ? tableRow : []}
          columns={ColumnPaymentLinks ? ColumnPaymentLinks : []}
          emptyPlaceHolder="You currently do not have any payment links"
          total={paymentLinks?.items?.length}
        />
      )}
    </Fragment>
  );
};

export default PaymentLinks;
