import { Route, useHistory } from "react-router-dom";

const PublicRoute = ({ children, ...rest }: any) => {
  const access_token = sessionStorage.getItem("access_token");
  const history = useHistory();
  return (
    <Route
      {...rest}
      render={() => (!access_token ? children : history.goBack())}
    />
  );
};

export default PublicRoute;
