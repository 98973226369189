import React, { Fragment, useEffect, useState } from "react";
import styles from "./Sidebar.module.scss";
import { ReactComponent as LogoOne } from "../../assets/images/Logos/logo-one.svg";
import { ReactComponent as DropdownIcon } from "../../assets/images/Icons/dropdown-arrow.svg";
import { ReactComponent as NewBusinessIcon } from "../../assets/images/Icons/add-business.svg";
import { ReactComponent as SignOutIcon } from "../../assets/images/Icons/sign-out.svg";
import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import { SidebarRoutes } from "../../constants/sidebarRoutes";
import { useHistory } from "react-router-dom";
import { Businesses } from "../../constants/businesses";
import { useDispatch, useSelector } from "react-redux";
import { changeNavbar } from "../../redux/actions/navbar/navbarActions";
import Modal from "react-modal";
import { customStyles } from "../../constants/modalStyles";
import AddNewSub from "../ModalContent/AddNewSub/AddNewSub";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  ToastErrorStyles,
  ToastSuccessStyles
} from "../../constants/toastStyles";
import { saveSubsidiaryInfo } from "../../redux/actions/subsidiaryInfo/subsidiaryInfoActions";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import ClickAwayListener from "@mui/material/ClickAwayListener";

const SideBar = () => {
  const todaysDate = new Date();

  const [defaultSub, setDefaultSub] = useState<any>();
  const [dropdown, setDropdown] = useState(false);
  const toggleDropdown = () => {
    setDropdown(!dropdown);
  };

  const handleClickAway = () => {
    setDropdown(false);
  };
  const dispatch = useDispatch();
  const history = useHistory();
  const [selectedNav, setSelectedNav] = useState("");

  const [modalIsOpen, setIsOpen] = React.useState(false);
  function closeModal() {
    setIsOpen(false);
  }

  const { subsidiaryInfo } = useSelector(
    (state) => state.subsidiaryInfoReducer
  );

  const setSubsidiarySession = async (id: number) => {
    dispatch(openLoader());
    try {
      const data: any = await client.post(
        "/subsidiary/dashboard/session/set-subsidiary",
        { subsidiary_id: id, set_as_default: true }
      );
      const message = data?.data?.message;
      dispatch(closeLoader());
      dispatch(
        openToastAndSetContent({
          toastStyles: ToastSuccessStyles,
          toastContent: message
        })
      );
      getMe();
      window.location.reload();
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.status_code === 401) {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  const getMe = async () => {
    try {
      const data: any = await client.get("/subsidiary/dashboard/me");
      setDefaultSub(data?.data);
      // dispatch(saveSubsidiaryInfo(data?.data));
    } catch (error: any) {
      if (error?.response?.data?.status_code === 401) {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  const logout = async () => {
    try {
      const data: any = await client.post("/subsidiary/dashboard/logout", {
        login_hash: defaultSub?.login_hash,
        identifier: defaultSub?.user?.email_address
      });
      sessionStorage.clear();
      window.location.href = "/";
    } catch (error: any) {
      if (error?.response?.data?.status_code === 401) {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };

  function tailLongText(text: string) {
    if (text.length > 8) {
      return text.slice(0, 8) + "...";
    } else {
      return text;
    }
  }

  useEffect(() => {
    getMe();
  }, []);

  const subsidiary_details = subsidiaryInfo?.data?.subsidiary_details;

  return (
    <div className={styles.sideBar}>
      {dropdown ? (
        <ClickAwayListener onClickAway={handleClickAway}>
          <div className={styles.businessLogo}>
            <div className={styles.openLogoSection}>
              {!!defaultSub?.subsidiary_details?.subsidiaries[0]
                ?.subsidiary_logo && (
                  <div className={styles.logoStyles}>
                    <img
                      src={
                        defaultSub?.subsidiary_details?.subsidiaries[0]
                          ?.subsidiary_logo
                      }
                      alt={defaultSub?.subsidiary_details?.subsidiaries[0]?.name}
                      width={32}
                      height={32}
                    />
                  </div>
                )}
              <div className={styles.nameBusiness}>
                {!!defaultSub &&
                  tailLongText(
                    defaultSub?.subsidiary_details?.subsidiaries[0]
                      ?.business_name
                  )}
                <div className={styles.businessStatus}>
                  {defaultSub?.subsidiary_details?.subsidiaries[0]?.status}
                </div>
              </div>
              <DropdownIcon
                onClick={toggleDropdown}
                className={styles.dropdownTrue}
              />
            </div>
            <div className={styles.businessNumber}>
              Business({subsidiary_details?.total})
            </div>
            <>
              <div className={styles.subsidiaryDetails}>
                {subsidiary_details?.subsidiaries?.map((subsidiary: any) => (
                  <div
                    key={subsidiary?.id}
                    className={styles.newBusinesses}
                    onClick={() => setSubsidiarySession(subsidiary?.id)}
                  >
                    <div>
                      {subsidiary?.subsidiary_logo && (
                        <img
                          src={subsidiary?.subsidiary_logo}
                          alt={subsidiary?.name}
                          width={32}
                          height={32}
                        />
                      )}
                    </div>
                    <div className={styles.businessName}>
                      {subsidiary?.business_name}
                    </div>
                  </div>
                ))}
              </div>
              <div className={styles.moreOptions}>
                <div className={styles.addNew} onClick={() => setIsOpen(true)}>
                  <NewBusinessIcon />
                  <div className={styles.newBusiness}>Add new subsidiary</div>
                </div>
                <div
                  className={styles.signOutSection}
                  onClick={() => {
                    logout();
                  }}
                >
                  <SignOutIcon className={styles.signOutIcon} />
                  <div className={styles.signOut}>Sign out</div>
                </div>
              </div>
            </>
          </div>
        </ClickAwayListener>
      ) : (
        <div className={styles.businessLogo}>
          <div className={styles.openLogoSection}>
            {/* <LogoOne className={styles.logoStyles} /> */}
            {!!defaultSub?.subsidiary_details?.subsidiaries[0]
              ?.subsidiary_logo && (
                <div className={styles.logoStyles}>
                  <img
                    src={
                      defaultSub?.subsidiary_details?.subsidiaries[0]
                        ?.subsidiary_logo
                    }
                    alt={defaultSub?.subsidiary_details?.subsidiaries[0]?.name}
                    width={32}
                    height={32}
                  />{" "}
                  { }
                </div>
              )}
            <div className={styles.nameBusiness}>
              {!!defaultSub &&
                tailLongText(
                  defaultSub?.subsidiary_details?.subsidiaries[0]?.business_name
                )}
              <div className={styles.businessStatus}>
                {defaultSub?.subsidiary_details?.subsidiaries[0]?.status}
              </div>
            </div>
            <DropdownIcon
              onClick={toggleDropdown}
              className={styles.dropdownFalse}
            />
          </div>
        </div>
      )}
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Example Modal"
        style={customStyles}
      >
        <AddNewSub closeModal={closeModal} />
      </Modal>
      <ProSidebar width="264px" style={{ marginTop: "4rem" }}>
        <Menu
          iconShape="square"
          style={{
            position: "relative",
            overflowY: "scroll",
            height: "calc(100vh - 100px)"
          }}
        >
          {SidebarRoutes.map((navItem, index) => {
            return (
              <SubMenu title={navItem?.name} className={styles.navList}>
                {navItem?.subRoutes.map((submenu, index) => {
                  return (
                    <MenuItem
                      icon={submenu?.icon}
                      key={submenu?.id}
                      onClick={() => {
                        history.push(submenu?.route);
                        setSelectedNav(submenu?.id);
                        dispatch(changeNavbar(submenu?.name));
                      }}
                      active={selectedNav === submenu?.id}
                      className={
                        selectedNav === submenu?.id
                          ? styles.activeMenu
                          : styles.subMenu
                      }
                    >
                      {selectedNav === submenu?.id ? (
                        <div className={styles.activeColor}>
                          {submenu?.name}
                        </div>
                      ) : (
                        <div>{submenu?.name}</div>
                      )}
                    </MenuItem>
                  );
                })}
              </SubMenu>
            );
          })}
          <div className={styles.sidebarFooter}>
            <div>Copyright &#169; Payinvert {todaysDate.getFullYear()}.</div>
            <div>All rights reserved</div>
          </div>
        </Menu>
      </ProSidebar>
    </div>
  );
};

export default SideBar;
