import React, { useEffect } from "react";
import styles from "./Signup.module.scss";
import Logo from "../../assets/images/Logos/Pay-Invert-Logo.svg";
import { ReactComponent as SignInIcon } from "../../assets/images/Icons/signin.svg";
import { ReactComponent as AccountIcon } from "../../assets/images/Icons/account.svg";
import OutlineTextInput from "../../components/input/OutlineTextInput";
import {
  openLoader,
  closeLoader
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import client from "../../api/client";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import { saveSubsidiaryInfo } from "../../redux/actions/subsidiaryInfo/subsidiaryInfoActions";
import Radio from "@mui/material/Radio";
import Tooltip, { TooltipProps, tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { ReactComponent as InfoIcon } from "../../assets/images/Icons/info.svg";
import IconButton from "@mui/material/IconButton";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
  phoneInputStyle
} from "../../constants/toastStyles";
import countriesApi from "../../api/countries";
import useApi from "../../hooks/useApi";
import Select from "react-select";

const SignUp = () => {
  const todaysDate = new Date();
  const dispatch = useDispatch();
  const history = useHistory();
  interface initTypes {
    first_name: string;
    last_name: string;
    user_email: string;
    password?: string;
    Individual?: string;
    Company?: string;
    mobile?: string;
    name?: string;
    country: string;
  }

  const getCountriesApi = useApi(countriesApi.getCountries);

  const fetchCountries = async () => {
    getCountriesApi.request();
  };

  const [selectedCountry, setSelectedCountry] = React.useState<any>();

  let selectedCountryOptions = getCountriesApi?.data?.data?.map(function (
    selectedCountry: any
  ) {
    return {
      value: selectedCountry.short_name,
      label: selectedCountry.name,
      currency: selectedCountry.country_code
    };
  });

  const initProps: initTypes = {
    first_name: "",
    last_name: "",
    user_email: "",
    password: "",
    Individual: "Individual",
    mobile: !!selectedCountry?.currency ? `${selectedCountry?.currency}` : "",
    Company: "Company",
    name: "",
    country: ""
  };
  const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#016E20"
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#016E20",
      fontFamily: "'Kumbh Sans'",
      fontStyle: "normal",
      fontWeight: 500,
      fontSize: "14px",
      lineHeight: "20px",
      maxWidth: 260,
      padding: "8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      letterSpacing: "-0.006em",
      color: "#FFFFFF",
      boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.12)",
      borderRadius: "4px"
    }
  }));
  const [inputs, setInputs] = React.useState(initProps);

  const [selectedValue, setSelectedValue] = React.useState("");

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedValue(event.target.value);
  };

  const updateProps = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const inputName = event.target.name;
    setInputs((prevState: initTypes) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const payload = {
    name: inputs.name,
    business_type: selectedValue,
    user_details: {
      first_name: inputs.first_name,
      last_name: inputs.last_name,
      user_email: inputs.user_email,
      password: inputs.password
    }
  };

  useEffect(() => {
    fetchCountries();
  }, []);

  const [phoneNumber, setPhoneNumber] = React.useState(
    selectedCountry?.currency
  );
  const selectedCountryShort = selectedCountry?.value;

  const disabledFunction = () => {
    if (
      inputs.first_name === "" ||
      inputs.last_name === "" ||
      inputs.user_email === "" ||
      inputs.password === "" ||
      inputs.name === "" ||
      selectedCountry === "" ||
      phoneNumber === "" ||
      selectedValue === ""
    ) {
      return true;
    } else {
      return false;
    }
  };


  const handleSubmit = (e: React.SyntheticEvent) => {

    const actualPhone = (): any => {
      if (phoneNumber?.charAt(0) === "0") {
        const newPhone = phoneNumber?.replace(phoneNumber?.charAt(0), "")
        return newPhone
      } else {
        return phoneNumber
      }
    }
    if (disabledFunction()) {
      return dispatch(
        openToastAndSetContent({
          toastContent: "Please fill all the fields",
          toastStyles: ToastErrorStyles
        })
      );
    } else {
      e.preventDefault();
      dispatch(openLoader());
      client
        .post("/subsidiary/dashboard/signup", {
          ...payload,
          country: selectedCountryShort,
          mobile: `${selectedCountry?.currency}${actualPhone()
            ?.replace(/\s/g, " ")
            .trim()}`
        })
        .then((data: any) => {
          dispatch(closeLoader());
          history.push("/verify");

          const { status, message, token } = data?.data;
          const { access_token } = token;

          dispatch(
            openToastAndSetContent({
              toastContent: data?.message,
              toastStyles: ToastSuccessStyles
            })
          );
        })
        .catch((error: any) => {
          dispatch(closeLoader());
          const message = error?.response?.data?.message;
          dispatch(
            openToastAndSetContent({
              toastContent: message,
              toastStyles: ToastErrorStyles
            })
          );
        });
    }
  };
  return (
    <div className={styles.signInBody}>
      <div className={styles.logo}>
        <img src={Logo} width={207} height={56} alt="pay-invert" />
      </div>
      <div className={styles.signinForm}>
        <div className={styles.signIn}>Create an account</div>
        <div className={styles.businessForm}>
          <form onSubmit={handleSubmit} className={styles.form}>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="first_name"
              inputLabel="First name"
              inputValue={inputs.first_name}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="last_name"
              inputLabel="Last name"
              inputValue={inputs.last_name}
            />
            <OutlineTextInput
              handleChange={updateProps}
              inputName="user_email"
              inputLabel="Email Address"
              inputValue={inputs.user_email}
            />
            <div>
              <label className={styles.inputLabel}>Country</label>
              <Select
                defaultValue={selectedCountry}
                onChange={setSelectedCountry}
                options={selectedCountryOptions}
                className={styles.select}
                menuPortalTarget={document.body}
                styles={{
                  // Fixes the overlapping problem of the component
                  menu: (provided) => ({ ...provided, zIndex: 9999 })
                }}
              />
            </div>

            <div>
              <label className={styles.inputLabel}>Phone number</label>
              <div className={styles.phoneInput}>
                <input
                  type="text"
                  value={selectedCountry?.currency}
                  style={{
                    width: "20%",
                    fontFamily: "Kumbh Sans",
                    fontStyle: "normal",
                    fontWeight: 500,
                    fontSize: "16px",
                    lineHeight: "24px",
                    padding: "1rem",
                    borderRadius: "10px 0px 0px 10px",
                    border: "1px solid #616161"
                  }}
                />
                <input
                  type="number"
                  name="phoneNumber"
                  value={phoneNumber}
                  onChange={(e: any) => {
                    if (e.target.value.charAt(0) === 0) {
                      return null;
                    } else {
                      console.log(e.target.value);
                      setPhoneNumber(e.target.value)
                    }
                  }}
                  style={phoneInputStyle}

                  className="no-arrow"
                />
              </div>
            </div>

            <OutlineTextInput
              handleChange={updateProps}
              inputName="password"
              inputLabel="Password"
              inputType="password"
              inputValue={inputs.password}
            />
            <div className={styles.businessInfo}>Business information</div>
            <OutlineTextInput
              handleChange={updateProps}
              inputName="name"
              inputLabel="Business name"
              inputValue={inputs.name}
            />

            <div className={styles.businessType}>Business type</div>
            <div className={styles.formSelection}>
              <div className={styles.radioSelection}>
                <Radio
                  checked={selectedValue === inputs.Individual}
                  onChange={handleChange}
                  value={inputs.Individual}
                  name="Individual"
                  inputProps={{ "aria-label": inputs.Individual }}
                  size="small"
                />
                <div className={styles.radioLabel}>
                  Individual{" "}
                  <LightTooltip
                    title="Description of what business type 'individual' is"
                    placement="top"
                    arrow
                  >
                    <IconButton>
                      <InfoIcon className={styles.infoIcon} />
                    </IconButton>
                  </LightTooltip>
                </div>
              </div>
              <div className={styles.radioSelection}>
                <Radio
                  checked={selectedValue === inputs.Company}
                  onChange={handleChange}
                  value={inputs.Company}
                  name="Company"
                  inputProps={{ "aria-label": inputs.Company }}
                  size="small"
                />
                <div className={styles.radioLabel}>
                  Company{" "}
                  <LightTooltip
                    title="Description of what business type 'company' is"
                    placement="top"
                    arrow
                  >
                    <IconButton>
                      <InfoIcon className={styles.infoIcon} />
                    </IconButton>
                  </LightTooltip>
                </div>
              </div>
            </div>
            {disabledFunction() ? (
              <div className={styles.signInButtonDisabled}>
                <AccountIcon />
                <span>Create an account</span>
              </div>
            ) : (
              <div onClick={handleSubmit} className={styles.signInButton}>
                <AccountIcon />
                <span>Create an account</span>
              </div>
            )}
          </form>
        </div>
      </div>
      <div className={styles.newAccount}>
        <div className={styles.noAccount}>Already have an account?</div>
        <div
          className={styles.create}
          onClick={() => {
            history.push("/");
          }}
        >
          <SignInIcon />
          <span>Sign in</span>
        </div>
      </div>
      <div className={styles.sidebarFooter}>
        <div>Copyright &#169; Payinvert {todaysDate.getFullYear()}.</div>
        <div>All rights reserved</div>
      </div>
    </div>
  );
};

export default SignUp;
