import React, { useState, useEffect } from "react";
import styles from "./Customerdetails.module.scss";
import { useParams } from "react-router";
import axios from "axios";
import client from "../../api/client";
import {
  ToastSuccessStyles,
  ToastErrorStyles,
} from "../../constants/toastStyles";
import { useDispatch } from "react-redux";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import {
  closeLoader,
  openLoader,
} from "../../redux/actions/loader/loaderActions";
import { useHistory } from "react-router-dom";

const CustomerDetails = () => {
  const [customerDetails, setCustomerDetails] = useState<any>([]);
  const { id } = useParams<{ id: any }>();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const history = useHistory();

  useEffect(() => {
    dispatch(openLoader());
    setIsLoading(true);
    client
      .get(`/subsidiary/dashboard/fetch/customers?id=${id}`)
      .then((res: any) => {
        setCustomerDetails(res?.data?.items[0]);
        setIsLoading(false);
        dispatch(closeLoader());
      })
      .catch((error) => {
        dispatch(closeLoader());
        setIsLoading(false);
        if (error.response) {
          const { message } = error?.response?.data;
          dispatch(
            openToastAndSetContent({
              toastStyles: ToastErrorStyles,
              toastContent: message,
            })
          );
        } else if (error?.response?.data?.message === "Please login again") {
          sessionStorage.clear();
          window.location.href = "/";
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log("Error", error.message);
        }
      });
  }, []);

  return (
    <div>
      {isLoading ? (
        <div />
      ) : (
        <>
          <div className={styles.transactionsHeader}>
            <div
              style={{ color: "#616161", display: "inline", cursor: "pointer" }}
              onClick={() => history.goBack()}
            >
              Customer
            </div>{" "}
            {">"} Customer details
          </div>
          <div className={styles.topTable}>
            <div className={styles.detailsHeader}>Customer Details</div>

            <div className={styles.tableContent}>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Customer Name</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.customer_name}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Customer email</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.email_address}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Mobile phone</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.mobile_number}
                </div>
              </div>
              <div className={styles.customerInfo}>
                <div className={styles.detailsValue}>Country</div>
                <div className={styles.detailsKey}>
                  {customerDetails?.country_name}
                </div>
              </div>
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CustomerDetails;
