import React, { Dispatch, SetStateAction, useEffect } from "react";
import styles from "./kyc.module.scss";

import OutlineTextInput from "../../../components/input/OutlineTextInput";
import { ReactComponent as SaveIcon } from "../../../assets/images/Icons/save.svg";
import { openToastAndSetContent } from "../../../redux/actions/toast/toastActions";
import { ReactComponent as UploadIcon } from "../../../assets/images/Icons/upload-image.svg";
import ImagePlaceholder from "../../../assets/images/Icons/image-placeholder.png";

import {
  openLoader,
  closeLoader
} from "../../../redux/actions/loader/loaderActions";
import {
  ToastSuccessStyles,
  ToastErrorStyles
} from "../../../constants/toastStyles";
import { useDispatch, useSelector } from "react-redux";
import client, { formClient } from "../../../api/client";
import { useHistory } from "react-router-dom";

type SelectionProp = {
  selection?: string;
  setSelection: Dispatch<SetStateAction<string>>;
};
const Kyc = ({ selection, setSelection }: SelectionProp) => {
  const history = useHistory();
  interface initTypes {
    proofofaddress: any;
    id: any;
  }

  const dispatch = useDispatch();
  const initProps: initTypes = {
    proofofaddress: "",
    id: ""
  };
  const [inputs, setInputs] = React.useState(initProps);

  const [kycData, setKycData] = React.useState<any>();

  console.log(inputs);

  const updateImageProps = (event: any) => {
    const newValue = event?.target?.files[0];
    const inputName = event?.target?.name;
    setInputs((prevState: any) => {
      return {
        ...prevState,
        [inputName]: newValue
      };
    });
  };

  const fetchKycDetails = async () => {
    dispatch(openLoader());
    try {
      const { data }: any = await client.get(
        `/subsidiary/dashboard/kyc/detail/view`
      );
      const kycDetails = data?.data;
      setKycData(kycDetails);
      setInputs({
        proofofaddress: kycDetails?.proof_of_address,
        id: kycDetails?.identification
      });
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      const message = error?.response?.data?.message;

      dispatch(
        openToastAndSetContent({
          toastContent: message,
          toastStyles: ToastErrorStyles
        })
      );
    }
  };

  let formData = new FormData();

  formData.append("proofofaddress", inputs.proofofaddress); //append the values with key, value pair
  formData.append("id", inputs.id);

  const handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault();
    dispatch(openLoader());
    formClient
      .post("/subsidiary/dashboard/kyc/details/submit", formData)
      .then((data: any) => {
        dispatch(closeLoader());
        setSelection("Information");
        dispatch(
          openToastAndSetContent({
            toastContent: data?.data?.message,
            toastStyles: ToastSuccessStyles
          })
        );

        history.push("/dashboard/");
      })
      .catch((error: any) => {
        dispatch(closeLoader());
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles
          })
        );
      });
  };

  useEffect(() => {
    fetchKycDetails();
  }, []);

  return (
    <div className={styles.business}>
      <div className={styles.businessCard} style={{ marginTop: "30px" }}>
        <div className={styles.businessForm}>
          <form className={styles.form}>
            {!!inputs?.proofofaddress ? (
              <div className={styles.profileCard}>
                <div className={styles.logoText}>Proof of Address</div>

                <div className={styles.imagePlaceholder}>
                  <img
                    src={
                      // ? inputs?.proofofaddress
                      inputs?.proofofaddress

                      // : kycData?.proof_of_address !== inputs?.proofofaddress
                      // ? URL.createObjectURL(inputs?.proofofaddress)
                      // : ImagePlaceholder
                    }
                    alt="proofofaddress"
                    width={100}
                    height={100}
                    style={{ borderRadius: "8px" }}
                  />
                </div>
                <div className={styles.uploadButton}>
                  <OutlineTextInput
                    inputName="proofofaddress"
                    inputLabel=""
                    handleChange={updateImageProps}
                    inputType="file"
                    inputShrink
                  />
                </div>
              </div>
            ) : (
              <OutlineTextInput
                inputName="proofofaddress"
                inputLabel="Proof of address"
                handleChange={updateImageProps}
                inputType="file"
                inputShrink
              />
            )}

            {!!inputs?.id ? (
              <div className={styles.profileCard}>
                <div className={styles.logoText}>Means of Identification</div>

                <div className={styles.imagePlaceholder}>
                  <img
                    src={inputs?.id}
                    alt="means of identification"
                    width={100}
                    height={100}
                    style={{ borderRadius: "8px" }}
                  />
                </div>
                <div className={styles.uploadButton}>
                  <OutlineTextInput
                    inputName="id"
                    inputLabel="Means of identification"
                    handleChange={updateImageProps}
                    inputType="file"
                    inputShrink
                  />
                </div>
              </div>
            ) : (
              <OutlineTextInput
                inputName="id"
                inputLabel="Means of identification"
                handleChange={updateImageProps}
                inputType="file"
                inputShrink
              />
            )}
          </form>
          <div
            className={styles.saveButton}
            onClick={(e) => {
              handleSubmit(e);
            }}
          >
            <SaveIcon /> <span>Save and Continue</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Kyc;
