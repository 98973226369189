import React, { useState, Fragment, useEffect } from "react";
import styles from "./Refunds.module.scss";
import PaginationTable from "../../components/table/pagination-table";
import { ColumnRefund, RefundsData } from "../../types/TablesTypes/Refunds";
import ExportButton from "../../components/ExportButton/ExportButton";
import StatusView from "../../components/StatusView/StatusView";
import { ReactComponent as DropArrow } from "../../assets/images/Icons/drop-arrow.svg";
import refundApi from "../../api/refunds";
import useApi from "../../hooks/useApi";
import { numberWithCommas } from "../../utils/formatNumber";
import TableHeader from "../../components/TableHeader/TableHeader";
import moment from "moment";
import RefundMenu from "../../components/MenuOption/RefundMenu/RefundMenu";
import {
  openLoader,
  closeLoader,
} from "../../redux/actions/loader/loaderActions";
import { useDispatch } from "react-redux";
import { ToastErrorStyles } from "../../constants/toastStyles";
import { openToastAndSetContent } from "../../redux/actions/toast/toastActions";
import client from "../../api/client";

const Refunds = () => {
  const dispatch = useDispatch();

  const [value, setValue] = useState("");
  const [status, setStatus] = useState<string>("");
  const [refunds, setRefunds] = useState<any>();
  const [tableRow, setTableRow] = useState<any[]>();
  const getRefunds = useApi(refundApi.getRefunds);

  const fetchRefunds = async () => {
    dispatch(openLoader());
    try {
      const { data } = await client.get(
        `/subsidiary/dashboard/refund/all?status=${status}`
      );
      setRefunds(data);
      dispatch(closeLoader());
    } catch (error: any) {
      dispatch(closeLoader());
      if (error?.response?.data?.message === "Please login again") {
        sessionStorage.clear();
        window.location.href = "/";
      } else {
        const message = error?.response?.data?.message;

        dispatch(
          openToastAndSetContent({
            toastContent: message,
            toastStyles: ToastErrorStyles,
          })
        );
      }
    }
  };
  useEffect(() => {
    fetchRefunds();
  }, [status]);

  const dataTransaction = () => {
    const tempArr: RefundsData[] = [];
    refunds?.items
      ?.slice(0)
      .reverse()
      .forEach((refund: any, index: number) => {
        tempArr.push({
          date: moment(refund?.date_created).format("MMMM Do YYYY, h:mm a"),
          customerEmail: refund?.customer_email,
          transactionAmount: `${refund?.currency} ${numberWithCommas(
            refund?.amount
          )}`,
          refundAmount: `${refund?.currency} ${numberWithCommas(
            refund?.refund_amount
          )}`,
          reference: refund?.order_reference,
          status: (
            <StatusView
              status={refund?.refund_status}
              green="Completed"
              red="Declined"
              orange="Processing"
            />
          ),
          action: <RefundMenu data={refund} />,
        });
      });
    return tempArr;
  };

  useEffect(() => {
    setTableRow(dataTransaction());
  }, [refunds?.items]);
  return (
    <Fragment>
      <TableHeader
        pageName="Refunds"
        data={refunds?.items}
        value={value}
        setValue={setValue}
        dataLength={refunds?.items?.length}
        FilterComponent={
          <div>
            <div className={styles.filterSubHeading}>Status</div>
            <div className={styles.filterBody}>
              <div
                onClick={() => {
                  setStatus("Approved");
                }}
                style={{ cursor: "pointer" }}
              >
                Approved
              </div>
              <div
                onClick={() => setStatus("Processing")}
                style={{ cursor: "pointer" }}
              >
                Processing
              </div>
            </div>
          </div>
        }
      />
      {getRefunds.loading ? (
        <div className={styles.loading} />
      ) : (
        <PaginationTable
          value={value}
          data={tableRow ? tableRow : []}
          columns={ColumnRefund ? ColumnRefund : []}
          emptyPlaceHolder="You currently do not have any refunds"
          total={refunds?.items?.length}
        />
      )}
    </Fragment>
  );
};

export default Refunds;
