import { ReactComponent as DashboardIcon } from "../assets/images/Icons/dashboard.svg";
import { ReactComponent as ComplianceIcon } from "../assets/images/Icons/compliance.svg";
import { ReactComponent as TransactionIcon } from "../assets/images/Icons/transactions.svg";
import { ReactComponent as CustomerIcon } from "../assets/images/Icons/customers.svg";
import { ReactComponent as ProductIcon } from "../assets/images/Icons/products.svg";
import { ReactComponent as PaymentIcon } from "../assets/images/Icons/payment-links.svg";
import { ReactComponent as RefundIcon } from "../assets/images/Icons/refunds.svg";
import { ReactComponent as BalancesIcon } from "../assets/images/Icons/balances.svg";
import { ReactComponent as VirtualIcon } from "../assets/images/Icons/virtual-wallets.svg";
import { ReactComponent as SettlementIcon } from "../assets/images/Icons/settlements.svg";
import { ReactComponent as PayoutIcon } from "../assets/images/Icons/payouts.svg";
import { ReactComponent as ProfileIcon } from "../assets/images/Icons/profile.svg";
import { ReactComponent as PayoutAccountIcon } from "../assets/images/Icons/payout-account.svg";
import { ReactComponent as RolesIcon } from "../assets/images/Icons/roles-permissions.svg";
import { ReactComponent as UserIcon } from "../assets/images/Icons/users-subsidiaries.svg";
import { ReactComponent as PreferencesIcon } from "../assets/images/Icons/preferences.svg";
import { ReactComponent as AuditIcon } from "../assets/images/Icons/audit-trails.svg";
import { ReactComponent as ApiIcon } from "../assets/images/Icons/api-webhooks.svg";
import { ReactComponent as InvoiceIcon } from "../assets/images/Icons/invoices.svg";
import { v4 as uuidv4 } from "uuid";

export const SidebarRoutes = [
  {
    id: uuidv4(),
    name: "Home",
    subRoutes: [
      {
        id: uuidv4(),
        name: "Dashboard",
        route: "/dashboard",
        icon: <DashboardIcon />,
      },
      {
        id: uuidv4(),
        name: "Compliance",
        route: "/dashboard/compliance",
        icon: <ComplianceIcon />,
      },
    ],
  },
  {
    id: uuidv4(),
    name: "Business",
    subRoutes: [
      {
        id: uuidv4(),
        name: "Transactions",
        route: "/dashboard/business/transactions",
        icon: <TransactionIcon />,
      },
      {
        id: uuidv4(),
        name: "Invoices",
        route: "/dashboard/business/invoice",
        icon: <InvoiceIcon />,
      },
      {
        id: uuidv4(),
        name: "Customers",
        route: "/dashboard/business/customers",
        icon: <CustomerIcon />,
      },
      {
        id: uuidv4(),
        name: "Products",
        route: "/dashboard/business/products",
        icon: <ProductIcon />,
      },
      {
        id: uuidv4(),
        name: "Payment Links",
        route: "/dashboard/business/payment-links",
        icon: <PaymentIcon />,
      },
      {
        id: uuidv4(),
        name: "Refunds",
        route: "/dashboard/business/refunds",
        icon: <RefundIcon />,
      },
    ],
  },
  {
    id: uuidv4(),
    name: "Account",
    subRoutes: [
      {
        id: uuidv4(),
        name: "Settlement Balances",
        route: "/dashboard/account/balances",
        icon: <BalancesIcon />,
      },
      // {
      //   id: uuidv4(),
      //   name: "Virtual Wallets",
      //   route: "/dashboard/account/virtual-wallets",
      //   icon: <VirtualIcon />,
      // },
      {
        id: uuidv4(),
        name: "Settlements",
        route: "/dashboard/account/settlements",
        icon: <SettlementIcon />,
      },
      {
        id: uuidv4(),
        name: "Payouts",
        route: "/dashboard/account/payouts",
        icon: <PayoutIcon />,
      },
    ],
  },
  {
    id: uuidv4(),
    name: "Settings",
    subRoutes: [
      {
        id: uuidv4(),
        name: "Business",
        route: "/dashboard/settings/business",
        icon: <ProfileIcon />,
      },
      {
        id: uuidv4(),
        name: "Compliance Information",
        route: "/dashboard/settings/compliance-information",
        icon: <ComplianceIcon />,
      },
      {
        id: uuidv4(),
        name: "Payout Accounts",
        route: "/dashboard/settings/payout-accounts",
        icon: <PayoutAccountIcon />,
      },
      {
        id: uuidv4(),
        name: "Roles & Permissions",
        route: "/dashboard/settings/roles-permissions",
        icon: <RolesIcon />,
      },
      {
        id: uuidv4(),
        name: "User & Subsidiaries",
        route: "/dashboard/settings/subsidiaries",
        icon: <UserIcon />,
      },
      {
        id: uuidv4(),
        name: "Preferences",
        route: "/dashboard/settings/preference",
        icon: <PreferencesIcon />,
      },
      {
        id: uuidv4(),
        name: "Audit Trails",
        route: "/dashboard/settings/audit-trails",
        icon: <AuditIcon />,
      },
      {
        id: uuidv4(),
        name: "API Keys & Webhooks",
        route: "/dashboard/settings/api-webhooks",
        icon: <ApiIcon />,
      },
    ],
  },
];

export const activeNav = {
  fontFamily: "'Kumbh Sans'",
  fontStyle: "normal",
  fontWeight: 500,
  fontSize: "16px",
  lineHeight: "24px",
  display: "flex",
  alignItems: "center",
  letterSpacing: "-0.011em",
  color: "#016E20 !important",
  background: "#EDFAF1",
};
